import React, { Component, Fragment } from 'react';
import { Grid, Row, Col } from "react-bootstrap";
import { Button, Badge } from "reactstrap";
import Footer from "../../FrontEnd/Footer/Footer"
import Header from "../../FrontEnd/Header/Header"
import Heart from "../../../assets/img/heart.svg"
import CartIcon from "../../../assets/img/cart.svg"
import Clip3 from "../../../assets/img/dashboard/finishing-clip-3.png"
import Clip1 from "../../../assets/img/dashboard/finishing-clip-1.png"
import Clip7 from "../../../assets/img/dashboard/finishing-clip-7.png"
import Splash7 from "../../../assets/img/dashboard/splash-video-7.png"
import Checked from "../../../assets/img/checkWhite.svg"
class Cart extends Component {
  constructor(props) {
    super(props);
    var checkLogin = localStorage.getItem('user');
    if (checkLogin) {
      localStorage.removeItem('user');
    }
  }

  render() {
    return (
      <Fragment>
        <Header />
        <main className='container pt-2'>
          <h2 className='mt-0'>Your Cart Summary</h2>
          <p className='mb-2'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem has been the industry's standard dummy text ever since the 1500s, lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard the 1500s.</p>
          <Row>
            <Col xs={12} md={8}>
              <div className='flexElem gap25 pb-2 mb-2 borderB'>
                <img src={Clip3} alt='Clip3' width={72} />
                <div className='flexElem flexColumn'>
                  <h4 className='mt-0'>Order Name</h4>
                  <h3 className='text-primary m-0'>$123.44</h3>
                </div>
                <div className='cartItem ml-auto'>
                  <Button>
                    <img src={CartIcon} alt='CartIcon' width={32} />
                  </Button>
                  <img src={Checked} alt='Checked' width={32} className='cartChecked' />
                </div>
              </div>
              <div className='flexElem gap25 pb-2 mb-2 borderB'>
                <img src={Clip1} alt='Clip1' width={72} />
                <div className='flexElem flexColumn'>
                  <h4 className='mt-0'>Order Name</h4>
                  <h3 className='text-primary m-0'>$123.44</h3>
                </div>
                <div className='cartItem ml-auto'>
                  <Button>
                    <img src={CartIcon} alt='CartIcon' width={32} />
                  </Button>
                  <img src={Checked} alt='Checked' width={32} className='cartChecked' />
                </div>
              </div>
              <div className='flexElem gap25 pb-2 mb-2 borderB'>
                <img src={Clip7} alt='Clip7' width={72} />
                <div className='flexElem flexColumn'>
                  <h4 className='mt-0'>Order Name</h4>
                  <h3 className='text-primary m-0'>$123.44</h3>
                </div>
                <div className='cartItem ml-auto'>
                  <Button>
                    <img src={CartIcon} alt='CartIcon' width={32} />
                  </Button>
                  <img src={Checked} alt='Checked' width={32} className='cartChecked' />
                </div>
              </div>
              <div className='flexElem gap25 pb-2 mb-2 borderB'>
                <img src={Splash7} alt='Splash7' width={72} />
                <div className='flexElem flexColumn'>
                  <h4 className='mt-0'>Order Name</h4>
                  <h3 className='text-primary m-0'>$123.44</h3>
                </div>
                <div className='cartItem ml-auto'>
                  <Button>
                    <img src={CartIcon} alt='CartIcon' width={32} />
                  </Button>
                  <img src={Checked} alt='Checked' width={32} className='cartChecked' />
                </div>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className='altBG py-1 px-2'>
                <h4 className='mt-0'>Order Summary</h4>
                <div className='flexElem alignCenter spaceBetween mb-1'>
                  <p className='m-0'>Total Items: </p>
                  <h4 className='m-0'>4</h4>
                </div>
                <div className='flexElem alignCenter spaceBetween mb-2'>
                  <h5 className='m-0'>Sub Total:</h5>
                  <h3 className='text-primary m-0'>$2345.78</h3>
                </div>
                <Button block color='primary'>
                  I'm Ready to Pay
                </Button>
              </div>
            </Col>
          </Row>

        </main>
        <Footer />
      </Fragment >
    );
  }
}

export default Cart;
