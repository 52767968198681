import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Grid, Row, Col } from "react-bootstrap";
import { Button, Badge } from "reactstrap";
import { licenseService } from "../../../services/license";
// import Footer from "../FrontEnd/Footer/Footer"
// import Header from "../FrontEnd/Header/Header"
import Heart from "../../../assets/img/heart.svg"
import corporate from "../../../assets/img/corporate.png"
import influencer from "../../../assets/img/influencer.png"
import individual from "../../../assets/img/individual.png"
import Cart from "../../../assets/img/cart.svg"
import SearchIcon from "../../../assets/img/search.svg"
import axios from "axios";
import { corporateService } from 'services/corporate';
import { toast } from 'react-toastify';
import { alert } from 'redux/reducers/AlertReducer';
import Swal from "sweetalert2";


class LicenseSearch extends Component {
  constructor(props) {
    super(props);

    const queryParams = new URLSearchParams(window.location.search);
    const keyword = queryParams.get('keyword');

    this.state = {
      value: keyword ? keyword : "",
      results: [],
      suggestions: [],
      price: [],
      topSugPrice: [],
      networth: "",
      google_search_volume: "",
      instagram: "",
      twitter: "",
      redirect: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }




  handleSubmit(keyword, classification, networth, google_search_volume, instagram, twitter) {
    this.setState({ classification: classification, networth: networth, google_search_volume: google_search_volume, instagram: instagram, twitter: twitter });
    licenseService.checkCaretAvailability(keyword, classification, networth, google_search_volume, instagram, twitter).then((res) => {
      // console.log("######### PP=> ",res);

      this.setState({
        results: res,
        price: Array.isArray(res.price) && res.price.length > 0 ? res.price[0] : [],
        topSugPrice: Array.isArray(res.topSuggestion.price) && res.topSuggestion.price.length > 0 ? res.topSuggestion.price[0] : [],
        suggestions: res.suggestions,

      })
    })
  }



  mapDataWithPatterns = (data) => {
    const mappedData = {};

    const patterns = {
      keyword: /keyword/i,
      classification: /classification|class/i,
      net_worth: /net_worth|networth|net worth|worth/i,
      google_search_volume: /google_search_volume|google search volume|search volume/i,
      instagram: /instagram|instagram followers|insta/i,
      twitter: /twitter|twitter followers|twitter/i
    };

    Object.keys(patterns).forEach((standardKey) => {
      // Find the first matching key using the pattern
      for (let key in data) {
        if (patterns[standardKey].test(key)) {
          mappedData[standardKey] = data[key];
          break; // Stop after the first match
        }
      }
    });

    return mappedData;
  };

  handleCheckKeyword = () => {
    const { value } = this.state;

    licenseService.keywordCheckCaret(value).then((res) => {
      if (res.status === 403) {
        toast.error(res.message);
        // return;
        setTimeout(() => {
          window.location.reload();
        }, 700);
        return;
      } else {
        if (res.status === 200) {
          this.handleSearchClisification(value);
        }
      }
    })

  }

  componentDidMount() {
    if (this.state.value) {
      this.handleCheckKeyword();
    }
  }

  handleSearchClisification = async () => {
    const { value } = this.state;
    try {
      const response = await axios.get(`https://search.carets.tv/api/generate-text?keyword=${value}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors'

        }
      );
      // this.handleSubmit(response.keyword, response.classification, );
      const mappedData = this.mapDataWithPatterns(response);
      // console.log('Mapped Data:', mappedData);
      this.handleSubmit(mappedData.keyword, mappedData.classification, mappedData.net_worth, mappedData.google_search_volume, mappedData.instagram, mappedData.twitter);
      // Pass the mapped data to store function
      this.handleStoreSearchData(mappedData);
    } catch (error) {
      // console.log('Error fetching data:', error);
    }
  };

  handleStoreSearchData = (value) => {
    // console.log("VALUE => ",value);
    corporateService.storeCaretSearchData(value).then((res) => {
      // console.log("######### res => ",res);
    })
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      // this.handleSearchClisification();
      this.handleCheckKeyword();
    }
  }

  handleCorporateRequest = (planType) => {
    // Show SweetAlert on button click
    Swal.fire({
      title: "Authorized Agent Certification",
      html: `
      <p style="text-align: justify;">
       I certify I am an authorized agent, stakeholder, and/or owner’s representative of the respective organization associated with this ^Caret license.
       I agree to verify and provide any required documentation as deemed necessary by Carets Corporation to confirm identity and corporate association.
       I agree to speak with a ^Carets representative if necessary to facilitate this license agreement and purchase authorization. 
       I agree to the ^Caret licensing 
        <a href="https://carets.tv/terms" target="_blank" style="color: #3085d6; text-decoration: underline;">
          Terms and Conditions
        </a>.
      </p>
    `,      
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "I Agree",
      cancelButtonText: "Cancel",
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        // Set redirect state to true after confirmation
        this.setState({ redirect: true });
        this.props.history.push({
          pathname: "/CLM/CorporateRequestForm",
          state: { keyword: this.state.value, plan:planType},
        });
        
      }
    });
  };



  render() {
    const {redirect, topSugPrice, sugPrice, results, suggestions, price, networth, google_search_volume, instagram, twitter, stripe_id_one_year, stripe_id_two_year, stripe_id_three_year } = this.state;

    return (
      <Fragment>
        <main className='content pl-2-5F pt-2'>
          <div className='clm-search'>
            <Button style={{ backgroundColor: "transparent", border: "none" }}><img src={SearchIcon} alt='SearchIcon' width={24} /></Button>
            <input type='text'
              placeholder='Reserve your ^Caret Today!'
              name="value"
              className='form-control'
              value={this.state.value}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
            />
            <Button className="searchBtn" disabled={this.state.value === ''} onClick={this.handleCheckKeyword}>Search ^Carets</Button>
          </div>
          {results && (results.status === "available" || results.status === "unavailable") && (
            <Row>
              <Col xs={12} md={6}>
                <div className='mainResult'>
                  <div className='py-50 px-2 searchBadge'>
                    Exact Match
                  </div>
                  <h4 className='mt-20'>{results.message}</h4>
                  {results.plan_type && 
                    <h5 className='mt-0 capitalize'>
                      {<img src={(results.plan_type == "individual" && individual || 
                        results.plan_type == "corporate" && corporate || 
                        results.plan_type == "influencer" && influencer)} alt="not found" height={20} className='mr-10' style={{filter:'brightness(0.2)'}}/>}
                      {results.plan_type} Account</h5>
                  }
                  {price.length === 0 || (price.contact_sales === 1 && price.contact_amount) ? (
                    <div></div>
                  ) : (
                    <h2 className='price mb-2'>$ {price && price.one_year_license}</h2>
                  )
                  }
                  {((price && price.length) === 0 || 
                      ((price && price.contact_sales) === 1 && (price && price.contact_amount)) || 
                      (price && price.one_year_license) > "1000") ? (
                    <div>
                        <Button
                          color="primary"
                          className="mt-2 px-4 btn btnDark text-white"
                          disabled={results.status === "unavailable"}
                          onClick={() => this.handleCorporateRequest(results.plan_type)}
                        >
                          Make it yours
                        </Button>
                  </div>
                ) : (

                    <Link to={{
                      pathname: "/CLM/cart",
                      state: {
                        keyword: this.state.value, plan: results.plan_type, price: price && price.one_year_license, two_year_license: price && price.two_year_license, three_year_license: price && price.three_year_license,
                        stripe_id_one_year: price.stripe_id_one_year, stripe_id_two_year: price.stripe_id_two_year, stripe_id_three_year: price.stripe_id_three_year,
                        networth: networth, googleSearch: google_search_volume, instagram: instagram, twitter: twitter
                      }
                    }}>
                      <Button color='primary' className='mt-2 px-4 btn btnDark text-white' disabled={results.status === "unavailable"}>
                        Make it yours
                      </Button>
                    </Link>
                  )}

                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className='mainResult'>
                  <div className='py-50 px-2 searchBadge'>
                    Exact Match Alternate
                  </div>
                  <div>
                    <h4 className='mt-20'>{results.topSuggestion.keyword}</h4>
                    {results.topSuggestion.plan_type &&
                      <h5 className='mt-0 capitalize'>  
                      {<img src={individual} alt="not found" height={20} className='mr-10' style={{filter:'brightness(0.2)'}}/>}                     
                        {results.topSuggestion.plan_type} Account</h5>
                    }
                  </div>
                  <h2 className='price mb-2'>${(topSugPrice && topSugPrice.one_year_license)}</h2>

                  {results.topSuggestion.plan_type === "Corporate" ? (
                    <Link to={{
                      pathname: "/CLM/CorporateRequestForm",
                      state: {
                        keyword: results.topSuggestion.keyword, plan: results.topSuggestion.plan_type, price: topSugPrice && topSugPrice.one_year_license, two_year_license: topSugPrice && topSugPrice.two_year_license, three_year_license: topSugPrice && topSugPrice.three_year_license,
                        stripe_id_one_year: topSugPrice.stripe_id_one_year, stripe_id_two_year: topSugPrice.stripe_id_two_year, stripe_id_three_year: topSugPrice.stripe_id_three_year,
                        networth: '0', googleSearch: '0', instagram: 0, twitter: '0'
                      }
                    }}>
                      <Button color='primary' className='mt-2 px-4 btn btnDark text-white' disabled={results.status === "unavailable"}>
                        Make it yours
                      </Button>
                    </Link>
                  ) : (
                    <Link to={{
                      pathname: "/CLM/cart",
                      state: {
                        keyword: results.topSuggestion.keyword, plan: results.topSuggestion.plan_type, price: topSugPrice && topSugPrice.one_year_license, two_year_license: topSugPrice && topSugPrice.two_year_license, three_year_license: topSugPrice && topSugPrice.three_year_license,
                        stripe_id_one_year: topSugPrice && topSugPrice.stripe_id_one_year, stripe_id_two_year: topSugPrice && topSugPrice.stripe_id_two_year, stripe_id_three_year: topSugPrice && topSugPrice.stripe_id_three_year,
                        networth: '0', googleSearch: '0', instagram: 0, twitter: '0'
                      }
                    }}>
                      <Button color='primary' className='mt-2 px-4 btn btnDark text-white'>
                        Make it yours
                      </Button>
                    </Link>
                  )}
                </div>
              </Col>
            </Row>
          )}
          {/* ########## SUGGESTIONS ########## */}
          {results && (results.status === "available" || results.status === "unavailable") && (
            <h4>Tailored Other Suggestions</h4>
          )}
          {suggestions && suggestions.length > 0 && (
            suggestions.map((suggestion, index) => (
              <div className='suggestions' key={index}>
                <div className='suggestionsList flexElem alignCenter gap25'>
                  <h4 className='m-0'>{suggestion && suggestion.keyword}</h4>
                  <div className='ms-auto flexElem alignCenter gap25'>
                    <h4 className='text-primary m-0'>${suggestion && suggestion.price && suggestion.price.one_year_license}</h4>
                    {suggestion.plan_type === "corporate" ? (
                      <Link to={{
                        pathname: "/CLM/CorporateRequestForm",
                        state: { keyword: suggestion.keyword, plan: suggestion.plan_type, price: suggestion.price }
                      }}>
                        <Button color='primary' className='mt-2 px-4' disabled={results.status === "unavailable"}>
                          Make it yours
                        </Button>
                      </Link>
                    ) : (
                      <Link to={{
                        pathname: "/CLM/cart",
                        state: {
                          keyword: suggestion.keyword, plan: suggestion.plan_type, price: suggestion.price.one_year_license,
                          two_year_license: suggestion.price.two_year_license, three_year_license: suggestion.price.three_year_license,
                          stripe_id_one_year: suggestion.price.stripe_id_one_year, stripe_id_two_year: suggestion.price.stripe_id_two_year, stripe_id_three_year: suggestion.price.stripe_id_three_year,
                          networth: '0', googleSearch: '0', instagram: 0, twitter: '0'
                        }
                      }}
                        className="cartFilter">
                        <img src={Cart} alt='Cart' width={24} />
                      </Link>

                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </main>
      </Fragment >
    );
  }
}

export default LicenseSearch;
