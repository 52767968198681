import React, { Component, Fragment } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover
} from "react-bootstrap";
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Card } from "components/Card/Card.jsx";
import 'react-table/react-table.css';
import { RiVideoLine } from 'react-icons/ri';
import { RiVideoDownloadFill } from "react-icons/ri";
import { FaVideoSlash } from "react-icons/fa";

import { FcProcess } from "react-icons/fc";

import { hasPermission } from 'helper/hasPermission';
import Confirmalertfordelete from '../../ThemeComponents/confirmAlertForDelete';
import { adminLabels } from '../../../redux/constant/admin-label-constant';
import OpenModalButton from '../../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import { ThemeFunctions as TF } from 'helper/ThemeFunctions';
import Pagination from '../../ThemeComponents/Pagination';
import DatePicker from "react-datepicker";
import { videoService } from '../../../services/video';
import { splashService } from '../../../services/splash';
import { Link } from 'react-router-dom';
import Progif from "../../../assets/img/progif.gif"
import Animation from '../../../assets/img/animated-download.gif';
import AngleDown from "../../../assets/img/angle_down.svg"
import AngleUp from "../../../assets/img/angle_up.svg"
import logoFrontEnd from "../../../assets/img/logo_frontEnd.png"


import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import { Cell } from 'recharts';
var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

const styles = {
  collapsibleContainer: {
    border: '1px solid #f1f1f1',
    borderRadius: '4px',
    marginBottom: '15px',
    padding: '5px 10px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toggleButton: {
    cursor: 'pointer',
    padding: '5px 0',
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  content: {
    overflow: 'hidden',
    transition: 'height 0.5s ease, opacity 0.5s ease',
    height: '0px',
    opacity: 0,
  },
  innerContent: {
    paddingTop: '10px',
    paddingBottom: '10px',
    textAlign: 'start'
  },
};
class MyCaretsVideoListingPage extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    const user = JSON.parse(localStorage.getItem('user'));
    this.dateValidator = new ReactValidator();
    var today = new Date();
    today.setDate(today.getDate() + 1);
    var lastmonth = new Date();
    lastmonth.setMonth(lastmonth.getMonth() - 1);
    var startDate = lastmonth.getFullYear() + '-' + (lastmonth.getMonth() + 1) + '-' + lastmonth.getDate();
    var endDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    this.state = {
      addModalForm: false,
      editModalForm: false,
      openVideoModal: false,
      openReportModal: false,
      openCaretModal: false,
      video_url: '',
      reportData: [],
      caretData: [],
      selectedVideo: [],
      filter: id,
      sorted: '',
      filtered: '',
      search: {
        fromDate: startDate,
        toDate: endDate,
        is_caret: 1,
      },

      pageSize: 10,
      userID: user.data ? user.data.id : null,
      downloading: null,
      showModal: false,
      collapsedItem1: true,
      collapsedItem2: true,
      collapsedItem3: true,
      collapsedItem4: true,
      collapsedItem5: true,
      catetData: [],
    };

    this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData = this.getPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.getUsersCall = this.getUsersCall.bind(this);
    this.videoModal = this.videoModal.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.contentRefs = {};

  };

  videoModal(url) {
    this.setState({ video_url: url }, () => {
      this.setState({ openVideoModal: true });
    });

  }

  reportModal(data, selectedVideo) {
    this.setState({ reportData: data, selectedVideo: selectedVideo }, () => {
      this.setState({ openReportModal: true });
    });
  }

  caretModal(data) {
    this.setState({ caretData: data }, () => {
      this.setState({ openCaretModal: true });
    });
  }

  submitForm(formData, formType) {
    if (formType === 'add') {
      videoService.storeVideo(formData).then(res => {
        this.setState({ addModalForm: false });
        this.pagination.dataCall();
      });
    } else if (formType === 'edit') {
      videoService.editVideo(formData, this.state.editRec.id).then(res => {
        this.setState({ editModalForm: false });
        this.pagination.dataCall();
      });
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id) {
    videoService.getVideo(id).then(res => {
      this.setState({ editRec: res.data }, () => {
        this.setState({ editModalForm: true });
      });
    });
  }

  deleteRecord(recID) {
    videoService.deleteVideo(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }

  getPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      splashService.paginationDataMyCarets(this.state.userID, page, filter, sort, pageSize, status, this.state.search);
    });
  }

  updateStatus = recID => (e) => {
    // return false;
    videoService.updateVideoStatus(recID, { is_active: e.target.checked ? 1 : 0 }).then(
      response => {
        videoService.paginationData(this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  updateBlocked = recID => (e) => {
    // return false;
    videoService.blockRecord(recID).then(
      response => {
        videoService.paginationData(this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    return splashService.paginationDataMyCarets(this.state.userID, data.page, data.filter || this.state.filter, data.sort, data.pageSize, this.state.rec_status, this.state.search);
  }

  getUsersCall() {
    videoService.getUsers().then(res => {
      this.setState({ users: res.data });
    });
  }

  // componentDidMount() {
  //     // this.getUsersCall();
  //   }

  closeRepoModal = () => {

    this.closeModal("openReportModal");
    splashService.paginationDataMyCarets(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
    this.pagination.dataCall();

  }
  closeCaretModal = () => {

    this.closeModal("closeCaretModal");
    splashService.paginationDataMyCarets(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
    this.pagination.dataCall();

  }

  DateChangeHandler = (value, field) => {
    const temp = cloneDeep(this.state.search);
    temp[field] = value === null ? '' : moment(value).format("YYYY-MM-DD");

    this.setState({ search: temp }, () => {

      if (this.dateValidator.allValid()) {
        splashService.paginationDataMyCarets(this.state.userID, this.props.current_page, '', '', this.state.pageSize, this.state.rec_status, this.state.search);
        this.pagination.dataCall();
      } else {
        this.dateValidator.showMessages();
        this.forceUpdate();
      }
    })
  }
  dateElement(label, name, value, rules) {
    var selectedDate = this.state.search[name] !== '' ? new Date(this.state.search[name]) : '';
    const { fromDate, toDate } = this.state.search;
    return (
      <div className='form-group fullCalendar' id={name}>
        <label>{label}</label>
        <DatePicker className="form-control calender_icon" selected={selectedDate} name={name} onChange={(event) => this.DateChangeHandler(event, name)} />
        {
          (fromDate !== '' && toDate !== '') &&
          this.dateValidator.message(name, value, rules)
        }
      </div>
    );
  }

  downloadVideo = async (videoUrl, fileName, videoId) => {
    try {
      this.setState({ downloading: videoId });

      const response = await fetch(videoUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the video:', error);
    } finally {
      this.setState({ downloading: null });
    }
  }
  // handleCaretChange = (checked) => {
  //   const temp = cloneDeep(this.state.search);
  //   temp['is_caret'] = checked;
  //   this.setState({ search : temp  }, () => {
  //       videoService.paginationData(this.props.current_page,'', '', this.state.pageSize, this.state.rec_status,this.state.search);
  //       this.pagination.dataCall();
  //   });
  // }
  // handleReportChange = (checked) => {
  //   const temp = cloneDeep(this.state.search);
  //   temp['is_reported'] = checked;
  //   this.setState({ search : temp  }, () => {
  //       videoService.paginationData(this.props.current_page,'', '', this.state.pageSize, this.state.rec_status,this.state.search);
  //       this.pagination.dataCall();
  //   });
  // }
  toggleCollapse = (item) => {
    this.setState((prevState) => ({
      [item]: !prevState[item], // Toggle the collapse state for the specific item
    }));
  };
  getContentHeight = (index) => {
    const content = this.contentRefs[index];
    return content ? `${content.scrollHeight}px` : '0px';
  };
  // ADDIG COLLAPSABLE CONTENT IN MODAL

  openModalSplash = (id) => {
    splashService.showCaretDatails(id).then(res => {
      this.setState({ caretData: res.data }, () => {

      })

    });

    this.setState({ showModal: true }, () => {
      setTimeout(() => {
        this.setState({ fadeIn: true });
      }, 100);
    });
  };

  closeModalSplash = () => {
    this.setState({ fadeIn: false }, () => {
      setTimeout(() => {
        this.setState({ showModal: false });
      }, 300);
    });
  };
  render() {
    const { filtered, editRec, users, downloading } = this.state;
    const { toDate, fromDate } = this.state.search;
    const columns = [
      {
        Header: "Video Listing",
        headerClassName: 'headerPagination',
        columns: [
          {

            Header: "Video",
            accessor: "video_url",
            Cell: ({ row, original, value }) => (

              <div>
                {(original.caret_processing !== 0 || original.is_active !== 1) ? <img src={Progif} style={{ hight: "25px", width: "30px" }} alt="processing" title='processing' /> :
                  (
                    original.image_url ? (
                      <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.openModalSplash(original.id)}>
                        <img style={{ hight: "25px", width: "40px" }} src={original.image_url} />
                      </div>
                    ) : (
                      <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.videoModal(original.video_url)}>
                        <RiVideoLine size={40} />
                      </div>
                    )
                  )
                  // <div style={{cursor:'pointer',fontSize:'20px'}} onClick={()=>this.videoModal(row.video_url)}>
                  //     <RiVideoLine />
                  // </div>
                }
              </div>
            ),

            className: ""
          },

          {
            Header: "Title",
            accessor: "video_title",
            className: "justifyStart text-left text-wrap",
            headerClassName: "justifyStart",
            // minWidth: 300
          },
          {
            Header: "Description",
            accessor: "video_description",
            className: "justifyStart text-left text-wrap",
            headerClassName: "justifyStart",
            minWidth: 300
          },

          {
            Header: "License",
            accessor: "license_id",
            Cell: ({ original }) => (
              original.license && original.license.caret_title ? original.license.caret_title : ""
            ),
            className: ""
          },

          {
            Header: "Like",
            accessor: "like_count",
            className: ""
          },
          {
            Header: "Watch",
            accessor: "watch_count",
            className: ""
          },
          {
            Header: "Shares",
            accessor: "share_count",
            className: ""
          },
          {
            Header: "Download",
            accessor: "video_raw_url",
            Cell: ({ value, original }) => (
              value ? (
                <a
                  href={value}
                  onClick={(e) => {
                    e.preventDefault();
                    if (downloading !== original.id) {
                      this.downloadVideo(value, `${original.video_description || 'video'}.mp4`, original.id);
                    }
                  }}
                  style={{
                    pointerEvents: downloading === original.id ? 'none' : 'auto',
                    opacity: downloading === original.id ? 0.5 : 1
                  }}
                >
                  {downloading === original.id ? <img src={Animation} style={{ hight: "100px", width: "80px" }} alt="downloading" title='downloading' /> : <RiVideoDownloadFill size={30} />}
                </a>
              ) : (
                <span><FaVideoSlash size={30} /></span>
              )
            )
          }


          // {
          //     Header: "Users",
          //     accessor: "users",
          //     Cell: ({value}) => (
          //       <div>
          //         {
          //           value.map((prop, key)=>{
          //             return(<span className="badge badge-success" key={key}>{prop.username}</span>)
          //           })
          //         }
          //       </div>
          //       ),
          //     className: "",
          //     sortable:false
          //   },



          // {
          //   Header: "Status",
          //   accessor: "is_active",
          //   Cell: ({row}) => (
          //       <label className="container-check">
          //         &nbsp;
          //         <input
          //           type="checkbox" name="is_active" className="checkbox" value={row.is_active? 1 : 0} onChange={this.updateStatus(row.id)}  checked={row.is_active?1:0}
          //          /><span className="checkmark"></span>
          //       </label>
          //     ),
          //   className: "",
          //   sortable:false
          // },

          // {
          //     Header: "Blocked",
          //     accessor: "is_blocked",
          //     Cell: ({row}) => (
          //         <label className="container-check">
          //           &nbsp;
          //           <input
          //             type="checkbox" name="is_blocked" className="checkbox" value={row.is_blocked? 1 : 0} onChange={this.updateBlocked(row.id)}  checked={row.is_blocked?1:0}
          //            /><span className="checkmark"></span>
          //         </label>
          //       ),
          //     className: "",
          //     sortable:false
          //   },

          // {
          //   Header:"Actions",
          //   accessor: "id",
          //   Cell: ({row}) => (
          //     <div>

          //     {row._original.videos.length>0 &&
          //           <span>
          //             <button type='button' className="caretIcon orderDetailsAction"
          //             onClick={() => this.caretModal(row._original.videos)}>
          //                 &nbsp;</button>
          //         </span>
          //       }

          //     {row._original.reported.length>0 &&
          //           <span>
          //             <button type='button' className="flagIcon orderDetailsAction" onClick={() => this.reportModal(row._original.reported,row._original)}>&nbsp;</button>
          //         </span>
          //       }

          //     <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Edit Video</Popover>}>
          //       <span>
          //       {
          //         <button type='button' className="editIcon orderDetailsAction" onClick={() => this.editRecord(row.id)}>&nbsp;</button>
          //       }

          //       </span>
          //     </OverlayTrigger>
          //     <OverlayTrigger placement="bottom" overlay={<Popover id="tooltip">Delete Video</Popover>}>
          //       <span>
          //       {
          //         <button type='button' className="deleteIcon orderDetailsAction" onClick={() => this.deleteConfirmModal(row.id)}>&nbsp;</button>
          //       }
          //       </span>

          //     </OverlayTrigger>
          //     </div>
          //     ),
          //   className: "justifyEnd text-right",
          //   headerClassName: "justifyEnd",
          //   sortable:false
          // }


        ]
      }
    ];
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                ctTableResponsive
                content={
                  <div>
                    <Grid fluid>
                      <Row>
                        <Col md={12} className='mb-20'>
                          <div className="secHeading">My Carets Video Listing</div>
                          <Link to="/CLM/custom-carets" className="btn btnDark text-white" style={{ width: 'auto' }}>CREATE A NEW CARET</Link>
                        </Col>
                        <Col md={3}>
                          <div className='row flexElem flexResponsive align-items-center' >
                            <div className="col-md-12" >
                              {
                                this.dateElement('Date From', 'fromDate', fromDate !== '' && moment(fromDate, 'YYYY-MM-DD'), [{ before_or_equal: moment(this.state.search.toDate) }])
                              }
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className='row flexElem flexResponsive align-items-center' >
                            <div className="col-md-12" >
                              {
                                this.dateElement('Date To', 'toDate', toDate !== '' && moment(toDate, 'YYYY-MM-DD'), [{ after_or_equal: moment(this.state.search.fromDate) }])
                              }
                            </div>
                          </div>
                        </Col>
                        <Col md={12}>
                          <Pagination
                            ref={(p) => this.pagination = p}
                            showPagination={false}
                            columns={columns}
                            pageSize={20}
                            getDataCall={this.paginationCall}
                            filterView={true}
                            filterPlaceHolder={'Carets'}
                            defaultSorted={
                              [
                                {
                                  id: 'id',
                                  desc: true
                                }
                              ]
                            }
                            // downloadData={true}
                            // downloadFileName={'Orders'}
                            // lowerContent = {null}
                            // filterPlaceHolder = {'Orders'}
                            noDataText='No Record found'
                            getRowProps={this.getRowProps}
                          // showAllToggle={true}
                          />
                        </Col>
                      </Row>
                    </Grid>
                    <div>
                      {
                        <Modal backdrop={'static'} show={this.state.openVideoModal} onHide={HF.closeModal.bind(this, "openVideoModal")} aria-labelledby="ModalHeader" >
                          <Modal.Header closeButton>
                            <Modal.Title id='ModalHeader' className="headerTitle">Video</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="row">
                              <div className="col-md-12" style={{ textAlignVertical: "center", textAlign: "center" }}>
                                <video key={this.state.video_url} id="playVid" controls style={{ width: 'auto', maxHeight: 'calc(100vh - 150px)' }} ><source src={this.state.video_url} type="video/mp4" /></video>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      }
                    </div>
                  </div>
                } />
            </Col>
          </Row>
        </Grid>
        <Grid fluid className="my-20">
          {this.state.showModal && (
            <Modal backdrop={'static'} show={this.state.showModal} onHide={this.closeModalSplash} aria-labelledby="ModalHeader">
              <Modal.Header closeButton>
                <Modal.Title id='ModalHeader' className="headerTitle">Caret Video Details</Modal.Title>
              </Modal.Header>
              <Modal.Body className="innerScrollModal">
                {this.state.caretData && (
                  <Row>
                    {this.state.caretData.license && (
                      <div>
                        <Col sm={12} md={12} className='mb-20'>
                          <div className='flexElem gap10p'>
                            {this.state.caretData.license.caret_logo ? (
                              <img src={this.state.caretData.license.caret_logo} alt="Caret Logo" style={{ maxHeight: 120 }} />
                            ) : (
                              <img src={logoFrontEnd} alt="No logo available" style={{ maxHeight: 120 }} />
                            )}
                            <h5 className='text-primary m-0 text-left'>
                              <strong className='mb-50'>License</strong> <br />
                              {this.state.caretData.license.caret_title}
                            </h5>
                          </div>
                        </Col>
                      </div>
                    )}
                    <Col sm={12} md={4}>
                      <video key={this.state.caretData.video_url} id="playVid" controls style={{ width: 'auto', maxHeight: 'calc(100vh - 150px)' }} ><source src={this.state.caretData.video_url} type="video/mp4" /></video>
                    </Col>
                    <Col sm={12} md={8} className='text-left'>
                      <h5 className='mt-0'><strong>Video Title:</strong> {this.state.caretData.video_title}</h5>
                      <p className='m-0'><strong>video Description:</strong> {this.state.caretData.video_description}</p>
                    </Col>
                    <div className='mb-2' style={{ clear: 'both' }} />
                    <Col md={12}>
                      {(this.state.caretData.videosplashes && this.state.caretData.videosplashes.length > 0) && (
                        <div style={styles.collapsibleContainer}>
                          <div style={styles.titleContainer}>
                            <button
                              onClick={() => this.toggleCollapse('collapsedItem1')}
                              style={styles.toggleButton}
                            >
                              <h6 className='text-primary m-0'>Start Splash</h6> {this.state.collapsedItem1 ? <img src={AngleUp} alt="up" width={20} /> : <img src={AngleDown} alt="Down" width={20} />}
                            </button>
                          </div>
                          <div
                            ref={(el) => (this.contentRefs[1] = el)}
                            style={{
                              ...styles.content,
                              height: this.state.collapsedItem1 ? '0px' : this.getContentHeight(1),
                              opacity: this.state.collapsedItem1 ? 0 : 1,
                            }}
                          >
                            <div style={styles.innerContent}>
                              {this.state.caretData.videosplashes.map((splash, index) => (
                                <div key={index}>
                                  {splash.finish === 0 && (
                                    <Row>
                                      < Col sm={12} md={4} >
                                        <video key={splash.video_url} id="playVid" controls style={{ width: 'auto', maxHeight: 'calc(100vh - 150px)' }} ><source src={splash.video_url} type="video/mp4" /></video>
                                      </Col>
                                      <Col sm={12} md={8}>
                                        <h6 className='fontNormal m-0'><strong>Splash Title:</strong> {splash.splash_title}</h6>

                                      </Col>
                                    </Row>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}

                      {(this.state.caretData.videos && this.state.caretData.videos.length > 0) && (
                        <div style={styles.collapsibleContainer}>
                          <div style={styles.titleContainer}>
                            <button
                              onClick={() => this.toggleCollapse('collapsedItem2')}
                              style={styles.toggleButton}
                            >
                              <h6 className='text-primary m-0'>Videos</h6> {this.state.collapsedItem2 ? <img src={AngleUp} alt="up" width={20} /> : <img src={AngleDown} alt="Down" width={20} />}
                            </button>
                          </div>

                          <div
                            ref={(el) => (this.contentRefs[2] = el)}
                            style={{
                              ...styles.content,
                              height: this.state.collapsedItem2 ? '0px' : this.getContentHeight(2),
                              opacity: this.state.collapsedItem2 ? 0 : 1,
                            }}
                          >
                            <div style={styles.innerContent}>
                              {this.state.caretData.videos.map((video, index) => (
                                <div key={index} className='mb-2'>
                                  <Row>
                                    <Col sm={12} md={4}>
                                      <video key={video.video_url} id="playVid" controls style={{ width: 'auto', maxHeight: 'calc(100vh - 150px)' }} ><source src={video.video_url} type="video/mp4" /></video>
                                    </Col>
                                    <Col sm={12} md={8}>
                                      <h6 className='fontNormal m-0'><strong>Video Detail:</strong> {video.video_description}</h6>

                                    </Col>
                                  </Row>
                                </div>
                              ))}

                            </div>
                          </div>
                        </div>
                      )}

                      {(this.state.caretData.videoads && this.state.caretData.videoads.length > 0) && (
                        <div style={styles.collapsibleContainer}>
                          <div style={styles.titleContainer}>
                            <button
                              onClick={() => this.toggleCollapse('collapsedItem3')}
                              style={styles.toggleButton}
                            >
                              <h6 className='text-primary m-0'>Ads Shown</h6> {this.state.collapsedItem3 ? <img src={AngleUp} alt="up" width={20} /> : <img src={AngleDown} alt="Down" width={20} />}
                            </button>
                          </div>
                          <div
                            ref={(el) => (this.contentRefs[3] = el)}
                            style={{
                              ...styles.content,
                              height: this.state.collapsedItem3 ? '0px' : this.getContentHeight(3),
                              opacity: this.state.collapsedItem3 ? 0 : 1,
                            }}
                          >
                            <div style={styles.innerContent}>
                              {this.state.caretData.videoads.map((ad, index) => (
                                <div key={index} className='mb-2'>
                                  <Row>
                                    < Col sm={12} md={4} >
                                      <video key={ad.video_url} id="playVid" controls style={{ width: 'auto', maxHeight: 'calc(100vh - 150px)' }} ><source src={ad.video_url} type="video/mp4" /></video>
                                    </Col>
                                    <Col sm={12} md={8}>
                                      <h6 className='fontNormal m-0'><strong>Ad Title:</strong> {ad.ad_title}</h6>
                                      <h6 className='fontNormal m-0'><strong>Ad Detail:</strong> {ad.ad_description}</h6>

                                    </Col>
                                  </Row>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}

                      {(this.state.caretData.videosplashes && this.state.caretData.videosplashes.length > 0) && (
                        <div style={styles.collapsibleContainer}>
                          <div style={styles.titleContainer}>
                            <button
                              onClick={() => this.toggleCollapse('collapsedItem4')}
                              style={styles.toggleButton}
                            >
                              <h6 className='text-primary m-0'>End Splash</h6> {this.state.collapsedItem4 ? <img src={AngleUp} alt="up" width={20} /> : <img src={AngleDown} alt="Down" width={20} />}
                            </button>
                          </div>
                          <div
                            ref={(el) => (this.contentRefs[4] = el)}
                            style={{
                              ...styles.content,
                              height: this.state.collapsedItem4 ? '0px' : this.getContentHeight(4),
                              opacity: this.state.collapsedItem4 ? 0 : 1,
                            }}
                          >
                            <div style={styles.innerContent}>
                              {this.state.caretData.videosplashes.map((splash, index) => (
                                <div key={index}>
                                  {splash.finish === 1 && (
                                    <Row>
                                      < Col sm={12} md={4} >
                                        <video key={splash.video_url} id="playVid" controls style={{ width: 'auto', maxHeight: 'calc(100vh - 150px)' }} ><source src={splash.video_url} type="video/mp4" /></video>
                                      </Col>
                                      <Col sm={12} md={8}>
                                        <h6 className='fontNormal m-0'><strong>Splash Title:</strong> {splash.splash_title}</h6>

                                      </Col>
                                    </Row>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}

                      {this.state.caretData.videosound ? (
                        <div style={styles.collapsibleContainer}>
                          <div style={styles.titleContainer}>
                            <button
                              onClick={() => this.toggleCollapse('collapsedItem5')}
                              style={styles.toggleButton}

                            >
                              <h6 className='text-primary m-0'>Selected Audio</h6> {this.state.collapsedItem5 ? <img src={AngleUp} alt="up" width={20} /> : <img src={AngleDown} alt="Down" width={20} />}
                            </button>
                          </div>
                          <div
                            ref={(el) => (this.contentRefs[5] = el)}
                            style={{
                              ...styles.content,
                              height: this.state.collapsedItem5 ? '0px' : this.getContentHeight(5),
                              opacity: this.state.collapsedItem5 ? 0 : 1,
                            }}
                          >
                            <div style={styles.innerContent}>
                              {this.state.caretData.videosound ?
                                <div className="splashWrap1">
                                  <div className="flexElem gap10p itemsCenter mb-1">
                                    <h6
                                      className="text-primary m-0"
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      Audio Title:
                                    </h6>
                                    <h6
                                      className="text-primary m-0"
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {this.state.caretData.videosound.sound_title}
                                    </h6>
                                  </div>
                                  <audio src={this.state.caretData.videosound.sound_url} className="URL no found" controls />
                                  <div
                                    className="adContent flexElem flexColumn mt-auto gap10p"
                                    style={{ wordBreak: "break-word" }}
                                  ></div>
                                </div>
                                :
                                <div className='flexElem alignCenter justifyCenter altBG px-1 py-1'>
                                  This video don't have sound
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      )
                        :
                        (
                          <div className='flexElem alignCenter justifyCenter altBG px-1 py-1'>
                            No sound in this video
                          </div>
                        )}
                    </Col>
                  </Row>
                )}
              </Modal.Body>
            </Modal>
          )}
        </Grid>
      </div>
    );
  };
}
const MyCaretsVideos = MyCaretsVideoListingPage;
export default MyCaretsVideos;
