import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover,

} from "react-bootstrap";
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Logo from "../../assets/img/logo_frontEnd.png"
import { objectsConstants as OC } from 'redux/constant/objects-constant';
import { RiVideoLine } from 'react-icons/ri';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-table/react-table.css';
import { FaRegImage } from "react-icons/fa";
import PaymentAds from "../../assets/img/paymentAds.svg";
import ActiveAds from "../../assets/img/activeAds.svg";
import CliksAds from "../../assets/img/cliksAds.svg";
import video_clips from "../../assets/img/carets-logo-v3.jpg";
import { MdAudiotrack } from "react-icons/md";
import A3 from "../../assets/img/A3.svg";
import totalCompaign from "../../assets/img/totalCompaign.svg";
import Confirmalertfordelete from '../ThemeComponents/confirmAlertForDelete';
import OpenModalButton from '../ThemeComponents/openModelButton';
import { helperFunctions as HF } from '../../helper/helperFunctions';
import Pagination from '../ThemeComponents/Pagination';
import { campaignService } from '../../services/campaign';
import { adService } from '../../services/ad';
import { licenseService } from '../../services/license';
import { splashService } from "../../services/splash";
import { musicService } from "../../services/music";
import Test from "./License/ImageCroping";

import ReactValidator from "simple-react-validator";
import "react-datepicker/dist/react-datepicker.css";

import Eye from "../../assets/img/eye.svg"
import eyeLogo from "../../assets/img/caretLogo.svg"

import AdVideoBanner from "../../assets/img/videoPreview.png"
import { Card, CardHeader, CardBody, CardTitle, CardText, Button } from 'reactstrap';

var cloneDeep = require('lodash.clonedeep');
var Modal = require('react-bootstrap-modal');
const moment = window.moment;

class DashboardPage extends Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem('user'));
    const { id } = this.props.match.params;
    this.state = {
      addModalForm: false,
      editModalForm: false,
      openVideoModal: false,
      openImageModal: false,
      openLogoModal: false,
      campaignModalForm: false,
      video_url: '',
      image_url: '',
      sorted: '',
      filtered: '',
      pageSize: 10,
      totalAds: 0,
      campaignID: id,
      userID: user.data ? user.data.id : null,
      campaignDetails: [],
      splashList: [],
      myCaretList: [],
      myLogoList: [],
      myVideoList: [],
      myAudioList: [],
      adList: [],
      status_id: false,
      searchTerm: '',
      searchAd: '',
      filter: '',
      license: [],
      isMobile: window.innerWidth < 1900,
      
    };

    this.editRecord = this.editRecord.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.deleteConfirmModal = this.deleteConfirmModal.bind(this);
    this.getPaginationData = this.getPaginationData.bind(this);
    this.filterSearchHandle = this.filterSearchHandle.bind(this);
    this.callFunction = this.callFunction.bind(this);
    this.closeModal = HF.closeModal.bind(this);
    this.videoModal = this.videoModal.bind(this);
    this.imageModal = this.imageModal.bind(this);
    this.logoModal = this.logoModal.bind(this);
    this.handleNewAdClick = this.handleNewAdClick.bind(this);
    this.getLicenseCall = this.getLicenseCall.bind(this);
  };


  handleNewAdClick = () => {
    this.setState({ campaignModalForm: true });
  }

  logoModal(id, url) {
    this.setState({licenseId:id, imagePreviewUrl:url}, ()=>{
        this.setState({openLogoModal:true});
    });

  }
  handleCrop = (imageFile) => {
    this.setState({ imageFile });
  };
  handelUploadCaretLogo = (image, userid, license_id) => {
    // console.log("image", license_id)
    const data = {
      image: image,
      user_id: userid,
      license_id:license_id
      }

    licenseService.uploadCaretLogo(data).then((res) => {
        // console.log("res", res);
        this.setState({openLogoModal: false});
        this.setState({licenseId:'', imageFile: null, imagePreviewUrl: null, videoName: ''});
        this.pagination.dataCall();
      });

  }

  videoModal(url) {
    this.setState({ video_url: url }, () => {
      this.setState({ openVideoModal: true });
    });
  }
  imageModal(url) {
    this.setState({ image_url: url }, () => {
      this.setState({ openImageModal: true });
    });
  }

  componentDidMount() {
    this.getLicenseCall();
    this.getRandomSplashCall(0);
    this.getRandomMyCaretCall(0);
    this.getRandomMyVideosCall(0);
    this.getRandomCaretLogoCall(0);
    this.getSoundsDataCall(0, this.state.searchTerm);
    this.getAdsCall(0, this.state.searchAd);
    console.log("componentDidMount")
    window.addEventListener('resize', this.updateScreenSize);
  }

  getLicenseCall(){
    licenseService.getCaretLicense().then(res => {
            console.log('license',res.data);
            this.setState({license:res.data});
          });
      }
  handleTypeChange = (selectedOption) => {
  this.setState({
    filter: selectedOption ? selectedOption.value : ''
    }, () => {
        this.getRandomSplashCall(this.state.filter);
        this.getRandomMyCaretCall(this.state.filter);
        this.getRandomMyVideosCall(this.state.filter);
        this.getRandomCaretLogoCall(this.state.filter);
        this.getSoundsDataCall(this.state.filter, this.state.searchTerm);
        this.getAdsCall(this.state.filter, this.state.searchAd);
        this.pagination.dataCall();
    });
  };


  getDurationsCall() {
    campaignService.getDuration().then(res => {
      this.setState({ durations: res.data });
    });
  }

  callFunction(formData, formType) {
    if (formType === 'add') {
      this.setState({ addModalForm: false });
      this.pagination.dataCall();
    } else if (formType === 'edit') {
      this.setState({ editModalForm: false });
      this.setState({ campaignModalForm: false });
      this.pagination.dataCall();
    }
  }

  filterSearchHandle(e) {
    this.setState({
      'filtered': e.target.value
    });
  }

  editRecord(id) {
    campaignService.getCampaignAds(id).then(res => {
      // console.log(res);
      this.setState({ editRec: res.data }, () => {
        this.setState({ editModalForm: true });
      });
    });
  }

  deleteRecord(recID) {
    campaignService.deleteCampaignAds(recID).then(res => {
      this.pagination.dataCall();
    });
  }

  deleteConfirmModal(recID) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmalertfordelete typeName="Record" description="Are you sure to delete the Record?" onClosePro={onClose} deleteType={() => this.deleteRecord(recID)} />
        )
      }
    })
  }


  getPaginationData(page, filter, sort, pageSize, status) {
    this.setState({ pageSize: pageSize }, function () {
      licenseService.paginationData(this.state.userID, page, filter, sort, pageSize, status, this.state.search);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.rec_status !== prevState.rec_status) {
      this.table.state.onFetchData(this.table.state, this.table.instance)
    }

  }

  paginationCall = (data) => {
    // console.log('data @@@@@@@@@@@@ ', this.state.userID);

    return licenseService.paginationData(this.state.userID, data.page, data.filter || this.state.filter, data.sort, data.pageSize, this.state.rec_status, this.state.search);
  }

  getAdsCall = (val, searchTerm) => {
    adService.getAdsDataRandom(val, searchTerm).then((res) => {
      this.setState({ adList: res.data });
    });
  }
  getRandomSplashCall = (val) => {
    splashService.getRandomSplash(val).then((res) => {
      this.setState({ splashList: res.data });
    });
  };
  getRandomMyCaretCall = (val) => {
    splashService.getRandomMyCaret(val).then((res) => {
      this.setState({ myCaretList: res.data });
    });
  };
  getRandomMyVideosCall = (val) => {
    splashService.getRandomMyVideos(val).then((res) => {
      this.setState({ myVideoList: res.data });
    });
  };
  getSoundsDataCall = (val, searchTerm) => {
    musicService.getSoundsDataRandom(val, searchTerm).then((res) => {
      this.setState({ myAudioList: res.data });
    });
  };
  getRandomCaretLogoCall = (val) => {
    licenseService.getRandomCaretLogo(val).then((res) => {
      this.setState({ myLogoList: res.data });
    });
  };
  updateScreenSize = () => {
    this.setState({ isMobile: window.innerWidth < 1900 });
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScreenSize);
  }
  render() {
    const { filtered, editRec, durations } = this.state;
    const columns = [
      {
        Header: "License Listing",
        headerClassName: 'headerPagination',
        columns: [
          {
            Header: "My Logo",
            accessor: "video_url",
            Cell: ({ row, original }) => (

              // console.log('original license', original.plan.title),
              original.plan && original.plan.title === "corporate" ? (
                original.caret_logo ? (

                  <div style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.logoModal(original.id, original.caret_logo)} title='edit logo'>
                    <img style={{hight:"25px", width:"40px"}} src={original.caret_logo} />
                  </div>
                ) : (
                  <div  style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => this.logoModal(original.id, original.caret_logo)} title='edit logo'>
                    <FaRegImage />
                  </div>
                )
              ):(
                <div>
                  <button type='button' className="caretIcon orderDetailsAction">
                      &nbsp;</button>
                </div>
                )
            ),
            sortable: false
          },
          {
            Header: "My ^Caret",
            accessor: "caret_title",
            sortable: false
          },
          {
            Header: "License Type",
            accessor: "title",
            Cell: ({ original }) => (
              original.plan ? (
                <div>{original.plan.title}</div>
              ) : (
                <div>No Plan</div>
              )
            ),
            className: "",
            sortable: false
          },
          {
            Header: "Start Date",
            accessor: "startedOn",
            Cell: ({ row, original }) => (
              <div>
                {original.payments && original.payments[0] ? HF.TStoDate(original.payments[0].current_period_start) : ''}
              </div>
            ),
            sortable: false
          },
          {
            Header: "End Date",
            Cell: ({ value, original }) => (
              <div>
                {original.payments && original.payments[0] ? HF.TStoDate(original.payments[0].current_period_end) : ''}
              </div>
            ),
            sortable: false
          },
          {
            Header: "Status",
            Cell: ({ value, original }) => {
              const status = original.status ? original.status.title : "";
              return (
                <div>
                  {status}
                </div>
              );
            },
            className: "",
            sortable: false
          }

        ]
      }
    ];
    const videosToShow = this.state.isMobile ? 8 : 12;
    const audioToShow = this.state.isMobile ? 3 : 3;
    const videoListToRender = this.state.myVideoList.slice(0, videosToShow);
    const caretListToRender = this.state.myCaretList.slice(0, videosToShow);
    const splashListToRender = this.state.splashList.slice(0, videosToShow);
    const adListToRender = this.state.adList.slice(0, videosToShow);
    const audioListToRender = this.state.myAudioList.slice(0, audioToShow);
    
    return (
      <div className="content">
        <Grid fluid>
          
          <Row>
            <Col md={6} className='mb-20'>
              <div className="secHeading mt-0">^Carets Dashboard</div>
              {/* <div className='cardTitle mt-lg'>My ^Caret Licenses</div> */}
            </Col>

            <Col md={6} className='flexElem alignCenter justifyEnd gap10p'>
              <Link to="/CLM/search" className="btn btnDark text-white" style={{ width: 'auto' }}>Purchase Additional ^Carets</Link>
              <Link to="/CLM/custom-carets" className="btn btnDark text-white" style={{ width: 'auto' }}>CREATE A NEW CARET</Link>
            </Col>
            
          </Row>
          <Row>
            <Col md={4}>
              <div className="fixBotNegative">
                <label htmlFor="plan"></label>
                <Select
                  name="typeSelect"
                  options={[
                    { value: "", label: "All Licenses" }, // Add "ALL" option at the top
                    ...(this.state.license || []), // Spread the rest of the licenses
                  ]}
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Select License"
                  value={
                    this.state.filter
                      ? [{ value: "", label: "All Licenses" }, ...(this.state.license || [])].find(
                          ls => ls.value === this.state.filter
                        )
                      : { value: "", label: "All Licenses" }
                  }
                  onChange={this.handleTypeChange}
                />
              </div>
            </Col>
            <Col md={12}>
              <Pagination
                ref={(p) => this.pagination = p}
                showPagination={false}
                columns={columns}
                pageSize={20}
                getDataCall={this.paginationCall}
                filterView={true}
                filterPlaceHolder={'License'}
                defaultSorted={
                  [
                    {
                      id: 'id',
                      desc: true
                    }
                  ]
                }
                // downloadData={true}
                // downloadFileName={'Orders'}
                // lowerContent = {null}
                // filterPlaceHolder = {'Orders'}
                noDataText='No Record found'
                getRowProps={this.getRowProps}
              // showAllToggle={true}
              />
            </Col>
            {
              <Modal backdrop={'static'} show={this.state.openVideoModal} onHide={HF.closeModal.bind(this, "openVideoModal")} aria-labelledby="ModalHeader" >
                <Modal.Header closeButton>
                  <Modal.Title id='ModalHeader' className="headerTitle">Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12" style={{ textAlignVertical: "center", textAlign: "center" }}>
                      <video key={this.state.video_url} id="playVid" controls style={{ width: 'auto', maxHeight: 'calc(100vh - 150px)' }} ><source src={this.state.video_url} type="video/mp4" /></video>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            }
            {
              <Modal backdrop={'static'} show={this.state.openImageModal} onHide={HF.closeModal.bind(this, "openImageModal")} aria-labelledby="ModalHeader" >
                <Modal.Header closeButton>
                  <Modal.Title id='ModalHeader' className="headerTitle">Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="col-md-12" style={{ textAlignVertical: "center", textAlign: "center" }}>
                      <div className='text-center'>
                        <img src={this.state.image_url} alt="Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            }
            {
                <Modal backdrop={'static'} show={this.state.openLogoModal} onHide={HF.closeModal.bind(this, "openLogoModal")} aria-labelledby="ModalHeader" >
                <Modal.Header closeButton>
                  <Modal.Title id='ModalHeader' className="headerTitle">Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Test  
                    // log={console.log("imagePreviewUrl aaaaaaaaaaa ",this.state.imagePreviewUrl)}
                    imagePreviewUrl={this.state.imagePreviewUrl}
                    onCrop={this.handleCrop} 
                    />
                  <div className='form-group'>
                    {this.state.imageFile &&
                      <div className='text-center'>
                        <Button onClick={() => this.handelUploadCaretLogo(this.state.imageFile, this.state.userID, this.state.licenseId )} bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
                      </div>
                    }
                  </div>
                </Modal.Body>
                </Modal>
            }
          </Row>

            {/* Carets */}
            {this.state.myCaretList.length > 0 &&
              <Card className='gridCard'>
                <CardTitle className='px-1 py-1 m-0 cardTitle' tag="h5">
                  <div className='flexElem alignCenter spaceBetween'>
                    <div>User Created Caret Videos
                    </div>
                    <Link to="/CLM/videos" className='text-primary text-14'>View All</Link>
                  </div>
                  
                </CardTitle>
                <CardBody className="px-1 py-1">
                  <div className="splashGrid">
                    {caretListToRender.map((myCaret, index) => (
                      <div key={index} onClick={() => this.videoModal(myCaret.video_url)} >
                        <div className="splashWrap">
                          {/* <video src={myCaret.video_url} className="adImage" /> */}
                          <img src={myCaret.image_url} className="adImage" />
                          <div
                            className="adContent flexElem flexColumn mt-auto gap10p"
                            style={{ wordBreak: "break-word" }}
                          >
                            <div>
                              <h6
                                className="text-primary m-0 splashTitle">
                                Video Title:
                              </h6>
                              <h5 className="splashSubTitle">{myCaret.label}</h5>
                            </div>
                            <div style={{ marginTop: "au to" }}>
                              <h6
                                className="text-primary m-0 splashTitle">
                                Your ^Caret:
                              </h6>
                              <h5 className="splashSubTitle">{myCaret.license && myCaret.license.caret_title}</h5>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            }

            {/* Videos */}
            {this.state.myVideoList.length > 0 &&
              <Card className='gridCard'>
                <CardTitle className='px-1 py-1 m-0 cardTitle' tag="h5">
                <div className='flexElem alignCenter spaceBetween'>
                    <div>Latest Uploaded Videos
                    </div>
                    <Link to="/CLM/videos" className='text-primary text-14'>View All</Link>
                  </div>
                </CardTitle>
                <CardBody className="px-1 py-1">
                  <div className="splashGrid">                  
                    {videoListToRender.map((myVideo, index) => (
                      <div key={index} onClick={() => this.videoModal(myVideo.video_url)}>
                        <div className="splashWrap">
                          <img src={myVideo.image_url} className="adImage" />
                          <div className="adContent flexElem flexColumn mt-auto gap10p" style={{ wordBreak: "break-word" }}>
                            <div>
                              <h6 className="text-primary m-0 splashTitle">Video Title:</h6>
                              <h5 className="splashSubTitle">{myVideo.label}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            }

            {/* Splash */}
            {this.state.splashList.length > 0 &&
              <Card className='gridCard'>
                <CardTitle className='px-1 py-1 m-0 cardTitle' tag="h5">
                  <div className='flexElem alignCenter spaceBetween'>
                    <div>Splash Images
                    </div>
                    <Link to="/CLM/splash" className='text-primary text-14'>View All</Link>
                  </div>
                </CardTitle>
                <CardBody className="px-1 py-1">
                  <div className="splashGrid">
                    {splashListToRender.map((splash, index) => (
                      <div key={index} onClick={() => this.videoModal(splash.video_url)} >
                        <div className="splashWrap splashDummyBG">
                          {/* <video src={splash.video_url} className="adImage" /> */}
                          <img src={splash.image_url} className="adImage" />
                          <div
                            className="adContent flexElem flexColumn mt-auto gap10p"
                            style={{ wordBreak: "break-word" }}
                          >
                            <div>
                              <h6
                                className="text-primary m-0 splashTitle">
                                Title:
                              </h6>
                              <h5 className="splashSubTitle">{splash.label}</h5>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            }

            {/* Ads */}
            {this.state.adList.length > 0 &&
              <Card className='gridCard'>
                <CardTitle className='px-1 py-1 m-0 cardTitle' tag="h5">
                  <div className='flexElem alignCenter spaceBetween'>
                    <div>Promotions Images</div>
                    <Link to="/CLM/ads" className='text-primary text-14'>View All</Link>
                  </div>
                </CardTitle>
                <CardBody className="px-1 py-1">
                  <div className="splashGrid">
                    {adListToRender.map((ad, index) => (
                      <div key={index} onClick={() => this.videoModal(ad.video_url)} >
                        <div className="splashWrap splashDummyBG">
                          {/* <video src={ad.video_url} className="adImage" /> */}
                          <img src={ad.image_url} className="adImage" />
                          <div
                            className="adContent flexElem flexColumn mt-auto gap10p"
                            style={{ wordBreak: "break-word" }}
                          >
                            <div>
                              <h6
                                className="text-primary m-0 splashTitle">
                                Title:
                              </h6>
                              <h5 className="splashSubTitle">{ad.label}</h5>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            }
            
            {/* Caret Logos */}
            {this.state.myLogoList.length > 0 &&
              <Card className='gridCard'>
                <CardTitle className='px-1 py-1 m-0 cardTitle' tag="h5">
                  <div className='flexElem alignCenter spaceBetween'>
                    <div>Watermark</div>
                    <Link to="/CLM/License" className='text-primary text-14'>View All</Link>
                  </div>
                </CardTitle>
                <CardBody className="px-1 py-1">
                  <div className="splashGrid">
                    {this.state.myLogoList.map((logo, index) => (
                      <div key={index} onClick={() => this.imageModal(logo.caret_logo)} >
                        <div className="splashWrap">
                          <div className='bgBlur' style={{ background: `url(${logo.caret_logo})` }}>
                            <img src={logo.caret_logo} className="adImage" />
                          </div>
                          <img src={logo.caret_logo} className="adImage" />
                          <div
                            className="adContent flexElem flexColumn mt-auto gap10p"
                            style={{ wordBreak: "break-word" }}
                          >
                            <div>
                              <h6
                                className="text-primary m-0 splashTitle">
                                Title:
                              </h6>
                              <h5 className="splashSubTitle">{logo.label}</h5>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardBody>

              </Card>
            }

            {/* Audios */}
            {this.state.myAudioList.length > 0 &&
              <Card className='gridCard'>
                <CardTitle className='px-1 py-1 m-0 cardTitle' tag="h5">
                  <div className='flexElem alignCenter spaceBetween'>
                    <div>Audio Tracks</div>
                    <Link to="/CLM/clm-audios" className='text-primary text-14'>View All</Link>
                  </div>
                </CardTitle>
                <CardBody className="px-1 py-1">
                  <div className="row">
                    {audioListToRender.map((audio, index) => (
                      <div className='col-12 col-sm-6 col-md-4 mb-2'>
                        {/* <div className='audioBG' style={{ ...(audio.image_url && { backgroundImage: `url(${audio.image_url})` }) }}> */}
                        <div className='audioBG'>
                          <div className="splashWrap1">
                            <div className="flexElem gap10p alignCenter mb-1">
                              {
                                audio.image_url?
                                (<img src={audio.image_url} className="roundedFull audioAVT" alt="Audio" />):
                                (
                                  <img src={video_clips} className="roundedFull audioAVT" alt="Audio" />
                                  
                                )
                              }
                              <div className='flexElem flexColumn gap5'>
                                <h6
                                  className="text-primary m-0"
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Audio Title:
                                </h6>
                                <h6
                                  className="text-primar m-0"
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {audio.label}
                                </h6>
                              </div>
                            </div>
                            <audio src={audio.sound_url} className="w-100" controls style={{ height: 48 }} />
                            <div
                              className="adContent flexElem flexColumn mt-auto gap10p"
                              style={{ wordBreak: "break-word" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            }



        </Grid>
      </div>
    );
  };
}
const Dashboard = DashboardPage;
export default Dashboard;
