import { SC } from "../helper/ServerCall";

export const appuserService = {
    storeUser,
    getUser,
    editUser,
    deleteUser,
    getCurrentUser,
    updatePermission,
    getUsersPagination,
    getOrderUsers,
    updateOrderModerator,
    updateUserStatus,
    updateBlocked,
    getAdvertisersPagination,
    storeAdvertiser,
    editAdvertiser,
    getIndustry,
    registerAdvertiser,
    updatePassword,
    resetPassword,
    sendPasswordResetLink,
    deleteAdvertiser,
    updateAdvertiser,
    getClmUsersPagination,
    storeClmUser,
    editClmUser,
    updateClmUser,
    deleteClmUser,
    registerClmUser,
    checkLogin
};

function sendPasswordResetLink(userData) {
    return SC.postCall("v1/sendPasswordResetLink", userData);
}

function updatePassword(userData) {
    return SC.postCall("users/changePassword", userData);
}

function resetPassword(userData) {
    return SC.postCall("v1/resetPassword", userData);
}

function storeUser(userData) {
    return SC.postCall("users", userData);
}

function storeAdvertiser(userData) {
    return SC.postCall("users/storeAdvertiser", userData);
}

function updateAdvertiser(userData, userId) {
    userData["user_id"] = userId;
    return SC.postCall("users/storeAdvertiser", userData);
}

function registerAdvertiser(userData) {
    return SC.postCall("check_signup_clm_user", userData);
}

function editAdvertiser(userData, userId) {
    userData["user_id"] = userId;
    return SC.postCall("users/updateAdvertiser", userData);
}

function getIndustry(filter) {
    const data = { filter: filter };
    return SC.postCall("users/getIndustry", data);
}

function getUser(userId) {
    return SC.getCall("users/" + userId);
}

function editUser(userData, userId) {
    return SC.putCall("users/" + userId, userData);
}

function deleteAdvertiser(userId) {
    return SC.getCall("deleteAdvertiser/" + userId);
}
function deleteUser(userId) {
    return SC.deleteCall("users/" + userId);
}

function getUsersPagination(page, filter, sort, pageSize, status) {
    return SC.getCall(
        "users-listing?page=" +
            page +
            "&filter=" +
            filter +
            "&sort=" +
            sort +
            "&pageSize=" +
            pageSize
    );
}

function getCurrentUser() {
    return SC.getCall("getUsersData");
}

function updatePermission(userId) {
    return SC.getCall("changePrimaryContact/" + userId);
}

function getOrderUsers() {
    return SC.getCall("getAllUsersDoNotHaveAgency");
}

function updateOrderModerator(data, orderId) {
    return SC.putCall("updateOrderDetailsUsers/" + orderId + "/update", data);
}

function updateUserStatus(userId, data) {
    return SC.putCall("users/updateStatus/" + userId, data);
}

function updateBlocked(userId, data) {
    return SC.putCall("users/blocked/" + userId, data);
}

function getAdvertisersPagination(page, filter, sort, pageSize, status) {
    return SC.getCall(
        "advertisers-listing?page=" +
            page +
            "&filter=" +
            filter +
            "&sort=" +
            sort +
            "&pageSize=" +
            pageSize
    );
}

// CLM Users
function getClmUsersPagination(page, filter, sort, pageSize, status) {
    return SC.getCall(
        "clmUsers-listing?page=" +
            page +
            "&filter=" +
            filter +
            "&sort=" +
            sort +
            "&pageSize=" +
            pageSize
    );
}
function storeClmUser(userData) {
    return SC.postCall("users/storeClmUser", userData);
}

function updateClmUser(userData, userId) {
    userData["user_id"] = userId;
    return SC.postCall("users/storeClmUser", userData);
}

function editClmUser(userData, userId) {
    userData["user_id"] = userId;
    return SC.postCall("users/updateClmUser", userData);
}
function deleteClmUser(userId) {
    return SC.getCall("deleteClmUser/" + userId);
}
function registerClmUser(userData) {
    return SC.postCall("check_sign_up_user", userData);
}
function checkLogin(userData) {
    return SC.postCall("check_login", userData);
}
