import { SC } from '../helper/ServerCall';

export const cardService = {
    paginationData,
    storeCard,
    getCard,
    editCard,
    deleteCard,
    updateCardStatus,
    showExistingCardData,
    storeExistingCard
}
function paginationData(user_id, page, filter, sort, pageSize,status,search=null) {
    const data = {'page': page,'filter': filter,'sort': sort,'pageSize': pageSize,'status': status,'search':search};
    return SC.getCall('cards/data/'+user_id+'?page=' + page + '&filter=' + filter + '&sort=' + sort + '&pageSize=' + pageSize);
}
function storeCard(catData) {
    return SC.postCall('cards/listing', catData);
}

function getCard(id) {

    return SC.getCall('cards/listing/' + id);
}

function editCard(data, cardId) {
    data['card_id'] = cardId;
    return SC.postCall('cards/listing', data);
}

function deleteCard(catId) {
    return SC.deleteCall('cards/listing/' + catId);
}
function updateCardStatus(catId,data=[]) {
    data['card_id'] = catId;
    return SC.postCall('cards/listing/status',data);
}
function showExistingCardData(user_id) {
    return SC.getCall('cards/data/' + user_id);
}
function storeExistingCard(card_id) {
    const payload = { card_id };
    return SC.postCall('cards/useExistingCard', payload);
}


