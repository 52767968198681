import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import html2pdf from 'html2pdf.js';
import V3 from '../../../assets/img/carets-logo-v3.jpg'
import V4 from '../../../assets/img/carets-logo-v4.jpg'
import Sign from '../../../assets/img/signature1.jpg'
import Sign2 from '../../../assets/img/signature2.jpg'
import Sign3 from '../../../assets/img/signature3.jpg'
import Sign4 from '../../../assets/img/signature4.jpg'
import Premium from '../../../assets/img/premium-carets.jpg'
import Award from '../../../assets/img/carets-award.jpg'

import { IoMdDownload } from "react-icons/io";


import "./emailpdf.css"
const baseURL = 'http://devadmin.carets.tv/'
class EmailPDF extends Component {
  constructor(props) {
    super(props);
    this.pdfRef = React.createRef();
  }
  handleDownload = () => {
    const element = this.pdfRef.current;
    if (!element) {
      console.error('PDF element not found.');
      return;
    }
    const { title, date } = this.props.location.state;
    const options = {
      margin: 1,
      filename: `certificate_^${title}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    html2pdf().from(element).set(options).save().then(() => {
      console.log('PDF generated and downloaded.');
    }).catch(error => {
      console.error('Error generating PDF:', error);
    });
  };

  render() {
    const { title, date } = this.props.location.state;

    return (
      <div className='mt-2-5'>
        {/* <button style={{ width: 'auto' }} onClick={this.handleDownload} className="btnDownload btnDark">
          <IoMdDownload />
        </button> */}
        <Button
          type="button"
          className="btnDark px-5 font-boldF text-16 me-1"
          onClick={this.handleDownload}
          style={{ width: 'auto', float: 'right' }}
        >
          <IoMdDownload />
        </Button>
        <div className='bodyWrap' ref={this.pdfRef}>
          <table className='certificateTable'>
            <tbody>
              <tr>
                <td style={{ width: '12.5%' }}><img src={V3} alt='logo1' className='image' /></td>
                <td style={{ width: '75%' }}>
                  <h1 className='textCenter' style={{ marginTop: 50 }}>CERTIFICATE</h1>
                  <h6 className='textCenter'>Licensing ^Carets</h6>
                </td>
                <td style={{ width: '12.5%' }}>&nbsp;</td>
              </tr>
              <tr style={{ paddingTop: 20 }}>
                <td></td>
                <td><h6 className='textCenter'>This is to certify that ^Carets</h6>
                  <h1 className='textCenter borderTB title'>^{title}</h1>
                  <p className='textCenter'>has been registered by Fun Store on behalf of Mark Anthony</p></td>
                <td>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div style={{ display: 'table', width: '100%' }}>
                    <div className='textCenter' style={{ verticalAlign: 'middle', display: 'table-cell' }}>
                      <img src={Sign} alt='logo1' className='image' />
                      <div className='textCenter' style={{ marginTop: 15 }}>
                        <h6 style={{ margin: 0, borderBottom: '1px solid #333', fontSize: 13 }}>John Martina</h6>
                        <p style={{ fontSize: 12, margin: 0 }}>President Director</p>
                      </div>
                    </div>
                    <div className='textCenter' style={{ verticalAlign: 'middle', display: 'table-cell', textAlign: 'center', padding: 8 }}>
                      <img src={Premium} alt='premiumCarets' className='image' /></div>
                    <div className='textCenter' style={{ verticalAlign: 'middle', display: 'table-cell' }}>
                      <img src={Sign2} alt='logo1' className='image' />
                      <div className='textCenter' style={{ marginTop: 15 }}>
                        <h6 style={{ margin: 0, borderBottom: '1px solid #333', fontSize: 13 }}>Alex Martina</h6>
                        <p style={{ fontSize: 12, margin: 0 }}>Genral Manager</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td style={{ verticalAlign: 'bottom' }}>
                  <img src={V3} alt='logo1' className='image' />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default EmailPDF;
