import React from 'react';
import {
	Button,
	Card
} from "react-bootstrap";
import InputMask from 'react-input-mask';

import upload from "../../../assets/img/upload.svg"
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import { helperFunctions as HF } from '../../../helper/helperFunctions';
import FileUpload from "components/Upload/FileUpload";
import ImagePreview from "components/Upload/ImagePreview";
import { corporateService } from "../../../services/corporate";

import { file } from 'jszip';

class CorporateForm extends React.Component {
	constructor(props) {
		super(props);
		const user = JSON.parse(localStorage.getItem('user'));

		this.validatorForm = new SimpleReactValidator({ autoForceUpdate: this, locale: 'en' });
		this.state = {
			req: {
				user_id: user.data ? user.data.id : null,
				caret_title: '',
				company_name: '',
				company_address: '',
				company_description: '',
				name: '',
				email: '',
				phone: '',
				designation: '',


			},
			error: '',
			videoPreviewUrl: this.props.recPro ? this.props.recPro.videoFile : null,
			submitted: false,
			formType: 'add',
			allowedFormatsStringMime: 'image/jpeg, image/png',
			allowedFormatsStringExt: 'jpg, png',
			allowedFormatsVideoMime: 'video/x-flv, video/mp4',
			allowedFormatsVideoExt: 'flv, mp4',
			videoFileName: '',
			videoAdded: false,
			videoDuration: ''

		};

		this.handleRec = this.handleRec.bind(this);
		this.handleRecSelect = this.handleRecSelect.bind(this);
		this.handleSubmitForm = this.handleSubmitForm.bind(this);
		this.handleSubmitRequestForm = this.handleSubmitRequestForm.bind(this);

	}
	handleRec(e) {
		const { name, value, type } = e.target;
		const recState = { ...this.state.req };
		recState[name] = type === 'checkbox' ? e.target.checked ? 1 : 0 : value;
		this.setState({ req: recState });
	}

	handleRecSelect(selectedOptionType, info) {
		if (info['action'] === 'select-option') {
			let recState = { ...this.state.req };
			recState[info['name']] = selectedOptionType['value'];
			this.setState({ req: recState });
		}
	}

	componentDidMount() {
		const { state } = this.props.location;
		console.log("state ######### ", state);
		if (state) {
		  this.setState({
			req: {
			  ...this.state.req,
			  caret_title: state.keyword,
			//   subscription_plan: state.plan,
			//   subscription_amount: state.price
			}
		  });
		}
	  }
	

	handleSubmitForm(e) {
		e.preventDefault();
		if (this.validatorForm.allValid() && (this.state.error == '' || this.state.error == null)) {
			this.setState({ submitted: true }, function () {
				
				this.handleSubmitRequestForm()

			})
		} else {
			this.setState({ submitted: false });
			this.validatorForm.showMessages();
			this.forceUpdate();
		}
	}

	handleSubmitRequestForm(){
		  // this.props.submitForm(this.state.license, this.state.formType);
		  if (this.state.formType === 'add') {
			corporateService.storeRequest(this.state.req).then(res => {
			  console.log('After', res)
			  window.location.replace("/CLM/corporate");
			});
		  }
	
	  
	}

	extractImageData = (value) => {
		const file = {};
		file['file'] = value['file'];
		file['name'] = value['name'];
		file['size'] = value['size'];
		file['extension'] = HF.returnExtFromImage(value['name']);
		file['preview'] = value['preview'];
		file['type'] = value['type'];
		file['lastModified'] = value['lastModified'];
		return file;
	}

	setResourceFile = (key, value) => {
		this.setState({
			req: { ...this.state.req, image: this.extractImageData(value) },
		});
	}

	removeFile = (key, index) => {
		this.setState({
			req: { ...this.state.req, image: null },
		});
	}

	removeVideoFile = (key, index) => {
		this.setState({
			req: { ...this.state.req, videoFile: null },
		});
	}

	setVideoFile = (event) => {
		const file = event.target.files[0];
		const fileData = {};
		fileData["name"] = file["name"];
		fileData['name'] = file['name'];
		fileData['size'] = file['size'];
		fileData['extension'] = HF.returnExtFromImage(file['name']);
		fileData['type'] = file['type'];
		fileData['lastModified'] = file['lastModified'];
		const video = document.createElement('video');
		const reader = new FileReader();
		video.onloadedmetadata = () => {
			const duration = Math.floor(video.duration);
			if (duration > 10) {
				this.setState({ error: 'Video duration exceeds the maximum allowed (10 seconds)' });

			} else if (file.size > 300 * 1024 * 1024) {
				this.setState({ error: 'Video size exceeds the maximum allowed (300 MB)' });

			} else {
				this.setState({ error: '' });
				const videoPreviewUrl = URL.createObjectURL(file);
				fileData['preview'] = videoPreviewUrl;
				this.setState({ videoDuration: duration, error: null });
                this.setState({ videoPreviewUrl:null},
                    () => {
                        this.setState({ videoPreviewUrl:videoPreviewUrl});

                })
				this.setState({ videoAdded: true });
				this.setState({ videoFileName: fileData["name"] });
				this.setState({
					req: { ...this.state.req, videoFile: fileData, duration: duration },
				});
			}
		};
		reader.readAsDataURL(file);
		reader.onload = () => {
			fileData['file'] = reader.result;
		};
		video.src = URL.createObjectURL(file);
		this.setState({
			req: { ...this.state.req, videoFile: fileData },
		});
	};

	handleInputChange = (event) => {
		const videoName = event.target.value.split("\\").pop();
		this.setState({ videoName });
	};

	 // Country masks
	 countryMasks = {
		US: "+1 (999) 999-9999",
		UK: "+44 9999 999 999",
		IN: "+91 99999 99999",
		// Add more countries as needed
	  };
	
	  // Example country options
	  countryOptions = [
		{ code: 'US', name: 'United States' },
		{ code: 'UK', name: 'United Kingdom' },
		{ code: 'IN', name: 'India' },
		// Add more countries as needed
	  ];

	render() {
		const { req, formType,selectedCountry, allowedFormatsStringMime, allowedFormatsStringExt, allowedFormatsVideoMime, allowedFormatsVideoExt } = this.state;
		const { users } = this.props;
		const { videoFileName, videoAdded } = this.state;
		return (
			<div className="content pl-2-5F">

            	<div className="secHeading">Corporate ^Caret Request Form</div>

				<form onSubmit={this.handleSubmitForm}>
					{formType === 'edit' &&
						<input type="hidden" name="id" className="form-control" value={req.id} />
					}
					<div className="row">
						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor="company_name">Company Name:<span className="requiredClass">*</span></label>
								<input type="text" name="company_name" className="form-control" value={req.company_name} onChange={this.handleRec} />
								{this.validatorForm.message('company_name', req.company_name, 'required|max:250')}
							</div>
						</div>
						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor="company_address">Company Address:<span className="requiredClass">*</span></label>
								<input type="text" name="company_address" className="form-control" value={req.company_address} onChange={this.handleRec} />
								{this.validatorForm.message('company_address', req.company_address, 'required|max:250')}
							</div>
						</div>
					</div>

					<div className="row">
						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor="name">Contact Person Name:<span className="requiredClass">*</span></label>
								<input type="text" name="name" className="form-control" value={req.name} onChange={this.handleRec} />
								{this.validatorForm.message('name', req.name, 'required|max:250')}
							</div>
						</div>
						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor="designation">Contact Person Designation:<span className="requiredClass">*</span></label>
								<input type="text" name="designation" className="form-control" value={req.designation} onChange={this.handleRec} />
								{this.validatorForm.message('designation', req.designation, 'required|max:250')}
							</div>
						</div>
					</div>

					<div className="row">
						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor="email">Contact Person Email:<span className="requiredClass">*</span></label>
								<input type="email" name="email" className="form-control" value={req.email} onChange={this.handleRec} />
								{this.validatorForm.message('email', req.email, 'required|max:250')}
							</div>
						</div>
						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor="phone">Contact Person Phone:<span className="requiredClass">*</span></label>
								{/* <input type="tel" pattern="^\+?[0-9]{4,14}" minLength={4} maxLength={14}  name="phone" className="form-control" value={req.phone} onChange={this.handleRec}  />
								{this.validatorForm.message('phone_number', req.phone, 'required')}
								{req.phone && !/^\+?[0-9]{4,14}$/.test(req.phone) && (
									<div className="text-danger">Phone number must be between 4 and 14 digits.</div>
								)} */}
								<InputMask
									mask="(999) 999-9999"
									value={req.phone}
									onChange={this.handleRec}
									className="form-control"
								>
									{(inputProps) => <input {...inputProps} type="text" name="phone" />}
								</InputMask>
								{this.validatorForm.message('phone_number', req.phone, 'required')}

							</div>
						</div>
					</div>

					<div className="row">
						<div className='col-md-12'>							
							<div className='form-group'>
								<label htmlFor="company_description">Description<span className="requiredClass">*</span></label>
								{/* <textarea name="company_description" className="form-control" onChange={this.handleRec}>
									{req.company_description}
								</textarea> */}
								 <textarea
									name="company_description"
									className="form-control"
									value={req.company_description || ""} // Use value instead of children
									onChange={this.handleRec}
								/>
								{
									this.validatorForm.message('company_description', req.company_description, 'required')
								}
							</div>
							
							<div className='form-group'>
								<div className='text-center'>
									<Button type='submit' bsSize="large" bsStyle="info" className="backButton pt-sm no_radius pb-sm success btn btn-lg btn-info ml-sm mt-sm btn-default " >Save</Button>
									{/* <Button bsSize="large" bsStyle="info" onClick={() => this.props.closeModel()} className="backButton pt-sm no_radius pb-sm primary btn btn-lg ml-sm mt-sm btn-info " >Cancel</Button> */}
								</div>
							</div>

						</div>
						
					</div>
				</form>

			</div>
		);
	}
}

export default CorporateForm;
