import Dashboard from "components/CLM/Dashboard";
import Tutorial from "components/CLM/Tutorial";
import dashboardIcon from "assets/img/dashboard.svg";

import EditProfile from "components/CLM/License/EditProfile";
import MyCaretsVideos from "components/CLM/License/MyCaretsVideos";
import AdListing from "components/CLM/Ads/AdListing";
import CorporateListing from "components/CLM/Corporate/CorporateListing";
import CorporateForm from "components/CLM/Corporate/CorporateForm";
import CorporateLicenseCart from "components/CLM/Corporate/CorporateLicenseCart";



import VideoListing from "components/CLM/Videos/VideoListing";
import CardListing from "components/CLM/CardPayment/CardListing";
import PaymentListing from "components/CLM/PaymentHistory/PaymentListing";
import SplashListing from "components/CLM/Splash/SplashListing";


import LicenseSearch from "components/CLM/License/LicenseSearch";
import LicenseCart from "components/CLM/License/LicenseCart";
import LicenseCard from "components/CLM/License/LicenseCard";
import LicenseListing from "components/CLM/License/LicenseListing";

import DefaultAudio from "components/CLM/License/DefaultAudio";
import DefaultAd from "components/CLM/License/DefaultAd";
import DefaultIntro from "components/CLM/License/DefaultIntro";
import DefaultFinish from "components/CLM/License/DefaultFinish";

import ImageCroping from "components/CLM/License/ImageCroping";

import MyCaretsForm1 from "components/CLM/License/MyCaretsForm1";
import MyCaretsForm2 from "components/CLM/License/MyCaretsForm2";
import MyCaretsForm4 from "components/CLM/License/MyCaretsForm4";
import MyCaretsForm5 from "components/CLM/License/MyCaretsForm5";
import CLMMusicCategories from "components/CLM/Music/CLMMusicCategories";
import CLMMusicListing from "components/CLM/Music/CLMMusicListing";



import Certificate from "components/CLM/pdf/Email";



import ChangePassword from "components/CLM/License/ChangePassword";



var CLMRoutes = [

  {
    path: "/CLM/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    iconImagePath: dashboardIcon,
    // permission: "CLM-dashboard"
  },
  {
    path: "/CLM/tutorial",
    name: "Tutorial",
    icon: "pe-7s-graph",
    component: Tutorial,
    iconImagePath: dashboardIcon,
    // permission: "CLM-dashboard"
  },
  {
    path: "/CLM/EditProfile",
    name: "EditProfile",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: EditProfile,
    //permission: "payments-list"
  },
  {
    path: "/CLM/videos",
    name: "Videos",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: VideoListing,
    // permission: "videos-list"
  },
  {
    path: "/CLM/ads/:id?",
    name: "Ads",
    icon: "pe-7s-graph",
    iconImagePath: dashboardIcon,
    component: AdListing,
    //permission: "ads-list"
  },
  {
    path: "/CLM/Corporate/:id?",
    name: "Corporate",
    icon: "pe-7s-graph",
    iconImagePath: dashboardIcon,
    component: CorporateListing,
    //permission: "ads-list"
  },
  {
    path: "/CLM/CorporateRequestForm",
    name: "Corporate",
    icon: "pe-7s-graph",
    iconImagePath: dashboardIcon,
    component: CorporateForm,
    //permission: "ads-list"
  },
  {
    path: "/CLM/splash",
    name: "Splash",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: SplashListing,
    // permission: "videos-list"
  },
  {
    path: "/CLM/License/:id?",
    name: "License",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: LicenseListing,
    // permission: "videos-list"
  },
  {
    path: "/CLM/defaultAudio/:id?",
    name: "DefaultSelection",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: DefaultAudio,
    // permission: "videos-list"
  },
  {
    path: "/CLM/defaultAd/:id?",
    name: "DefaultSelection",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: DefaultAd,
    // permission: "videos-list"
  },
  {
    path: "/CLM/defaultIntro/:id?",
    name: "DefaultSelection",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: DefaultIntro,
    // permission: "videos-list"
  },
  {
    path: "/CLM/defaultFinish/:id?",
    name: "DefaultSelection",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: DefaultFinish,
    // permission: "videos-list"
  },
  {
    path: "/CLM/search/:keyword?",
    name: "Search",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: LicenseSearch,
    // permission: "videos-list"
  },
  {
    path: "/CLM/cart",
    name: "Cart",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: LicenseCart,
    // permission: "videos-list"
  },
  {
    path: "/CLM/corporate-payment",
    name: "Corporate",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: CorporateLicenseCart,
    // permission: "videos-list"
  },
  {
    path: "/CLM/card",
    name: "Card",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: LicenseCard,
    // permission: "videos-list"
  },
  {
    path: "/CLM/imageCroping",
    name: "ImageCroping",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: ImageCroping,
    // permission: "videos-list"
  },
  {
    path: "/CLM/myCaretsVideos/:id?",
    name: "MyCaretsVideos",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: MyCaretsVideos,
    // permission: "my-carets-videos-list"
  },
  {
    path: "/CLM/custom-carets",
    name: "MyCaretsForm1",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: MyCaretsForm1,
    //permission: "campaigns-list"
  },
  {
    path: "/CLM/add-videos",
    name: "MyCaretsForm2",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: MyCaretsForm2,
    //permission: "campaigns-list"
  },
  {
    path: "/CLM/clm-audio-cats",
    name: "Audio Categories",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: CLMMusicCategories,
    // permission: "clm-audio-categories-list"
},
{
    path: "/CLM/clm-audios/:id?",
    name: "Audios",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: CLMMusicListing,
    // permission: "clm-audios-list"
},
  {
    path: "/CLM/end-spash",
    name: "MyCaretsForm4",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: MyCaretsForm4,
    //permission: "campaigns-list"
  },
  {
    path: "/CLM/cards/:id?",
    name: "Ads",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: CardListing,
    //permission: "cards-list"
  },
  {
    path: "/CLM/payments/:id?",
    name: "Ads",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: PaymentListing,
    //permission: "payments-list"
  },
  {
    path: "/CLM/certificate/:id?",
    name: "certificate",
    icon: "pe-7s-users",
    iconImagePath: dashboardIcon,
    component: Certificate,
    //permission: "payments-list"
  },
 
  { redirect: true, path: "/CLM", to: "/CLM/dashboard", name: "Dashboard" },

];

export default CLMRoutes;
