import React from "react";
import { Link } from "react-router-dom";
// import facebook from "../assets/imgs/facebook.svg";
import facebook from "../../../assets/img/facebook.svg";
import twitter from "../../../assets/img/twitter.svg";
import instagram from "../../../assets/img/instagram.svg";
import youtube from "../../../assets/img/youtube.svg";

import footer_logo from "../../../assets/img/footer_logo.svg";
import {
  Grid,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Button
} from "react-bootstrap";
const Footer = () => {
  return (
    <footer className="caretFooter">
      <section className="content">
        <div className="container">
          <Row>
            <Col md={3}>
              <h3>^Carets</h3>
              <ul className="list-none p-0 mt-5">
                <li className="">
                  <a
                    className=""
                    href="https://carets.tv/introduction"
                    target="_blank"
                  >
                    Introduction
                  </a>
                </li>
                <li className="">
                  <a
                    className=""
                    href="https://carets.tv/community-guidelines"
                    target="_blank"
                  >
                    Community Guidelines
                  </a>
                </li>
                <li className="">
                  <a
                    className=""
                    href="https://carets.tv/legal"
                    target="_blank"
                  >
                    Legal
                  </a>
                </li>
                <li className="">
                  <a
                    className=""
                    href="https://carets.tv/help"
                    target="_blank"
                  >
                    Help
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={3}>
              <h3>Resources</h3>
              <ul className="list-none p-0 mt-5">
                <li className=""><Link to="/">Creators</Link></li>
                <li className=""><Link to="/">Partners</Link></li>
              </ul>
            </Col>
            <Col md={3}>
              <h3>
                Company
              </h3>
              <ul className="list-none p-0 mt-5">
                <li className="">
                  <a
                    className=""
                    href="https://carets.tv/about"
                  >
                    About Carets
                  </a>
                </li>
                <li className="">
                  <a
                    className=""
                    href="https://carets.tv/terms"
                  >
                    Advertising T&C
                  </a>
                </li>
                <li className="">
                  <a
                    className=""
                    href="/"
                    target="_blank"
                  >
                    Advertise with Us
                  </a>
                </li>
                <li className=""><Link className="" to="/">Related Press</Link></li>
              </ul>
            </Col>
            <Col md={3}>
              <h3>
                Follow Us
              </h3>
              <ul className="socialIcons list-none">
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/profile.php?id=100069312018877"
                  >
                    <img
                      className=" absolute hidden"
                      src={facebook}
                      alt="Facebook"
                    />
                    <img
                      className=""
                      src={facebook}
                      alt="Facebook"
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://twitter.com/CaretsApp"
                  >
                    <img
                      className=" absolute hidden"
                      src={twitter}
                      alt="Twitter"
                    />
                    <img
                      className=""
                      src={twitter}
                      alt="Twitter"
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/caretsapp/"
                  >
                    <img
                      className=" absolute hidden"
                      src={instagram}
                      alt="Instagram"
                    />
                    <img
                      className=""
                      src={instagram}
                      alt="Instagram"
                    />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/channel/UCBb6TaWtn3BScytshsQksmw"
                  >
                    <img
                      className=" absolute hidden"
                      src={youtube}
                      alt="Youtube"
                    />
                    <img
                      className=""
                      src={youtube}
                      alt="Youtube"
                    />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        <div className="py-1 mt-1" style={{ borderTop: '1px solid #ddd' }}>
          <div className="container flexELem alignCenter spaceBetween1">
            <span>Copyright © 2020, Carets Corporation, All Rights Reserved.</span>
            <a href='https://carets.tv/' target="_blank" style={{ marginLeft: 'auto' }}>
              <img src={footer_logo} alt="footer_logo" width={60} />
            </a>
          </div>
        </div>
      </section>
      {/*
      <div
        className="bg-gray-200 w-full mt-16"
        style={{ height: "1px" }}
      ></div>
      <div className="customContainer py-7">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:text-left text-center">
          <div>
            <div className="text-xl font-semibold themeDarkBlue mt-5">
              ^Carets
            </div>
            <ul className="list-none p-0 mt-5">
              <li className="mt-3 text-lg 2xl:text-base">
                <Link
                  className="hover:text-blue-800 hover:underline"
                  to="/introduction"
                >
                  Introduction
                </Link>
              </li>
              <li className="mt-3 text-lg 2xl:text-base">
                <Link
                  className="hover:text-blue-800 hover:underline"
                  to="/community-guidelines"
                >
                  Community Guidelines
                </Link>
              </li>
              <li className="mt-3 text-lg 2xl:text-base">
                <Link
                  className="hover:text-blue-800 hover:underline"
                  to="/legal"
                >
                  Legal
                </Link>
              </li>
              <li className="mt-3 text-lg 2xl:text-base">
                <Link
                  className="hover:text-blue-800 hover:underline"
                  to="/help"
                >
                  Help
                </Link>
              </li>
            </ul>
          </div>

          <div>
                    <div className="text-xl font-semibold themeDarkBlue mt-5">Resources</div>
                    <ul className="list-none p-0 mt-5">
                        <li className="mt-3 text-lg 2xl:text-base"><Link className="hover:text-blue-800 hover:underline" to="/">Creators</Link></li>
                        <li className="mt-3 text-lg 2xl:text-base"><Link className="hover:text-blue-800 hover:underline" to="/">Partners</Link></li>
                    </ul>
                </div> 

      <div>
        <div className="text-xl font-semibold themeDarkBlue mt-5">
          Company
        </div>
        <ul className="list-none p-0 mt-5">
          <li className="mt-3 text-lg 2xl:text-base">
            <Link
              className="hover:text-blue-800 hover:underline"
              to="/about"
            >
              About Carets
            </Link>
          </li>
          <li className="mt-3 text-lg 2xl:text-base">
            <Link
              className="hover:text-blue-800 hover:underline"
              to="/terms"
            >
              Advertising T&C
            </Link>
          </li>
          <li className="mt-3 text-lg 2xl:text-base">
            <a
              className="hover:text-blue-800 hover:underline"
              href="https://admin.carets.tv/Advertiser-login"
              target="_blank"
            >
              Advertise with Us
            </a>
          </li>
          <li className="mt-3 text-lg 2xl:text-base"><Link className="hover:text-blue-800 hover:underline" to="/">Related Press</Link></li>
        </ul>
      </div>

      <div>&nbsp;</div>

      <div className="justify-self-center">
        <div className="text-xl font-semibold themeDarkBlue mt-5">
          Follow us
        </div>
        <ul className="socialIcons list-none p-0 mt-5 inline-grid grid-cols-4 gap-4 grid-flow-col-dense">
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/profile.php?id=100069312018877"
            >
              <img
                className="h-6 absolute hidden"
                src={facebook}
                alt="Facebook"
              />
              <img
                className="h-6"
                src={facebook}
                alt="Facebook"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/CaretsApp"
            >
              <img
                className="h-6 absolute hidden"
                src={twitter}
                alt="Twitter"
              />
              <img
                className="h-6"
                src={twitter}
                alt="Twitter"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/caretsapp/"
            >
              <img
                className="h-6 absolute hidden"
                src={instagram}
                alt="Instagram"
              />
              <img
                className="h-6"
                src={instagram}
                alt="Instagram"
              />
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/channel/UCBb6TaWtn3BScytshsQksmw"
            >
              <img
                className="h-6 absolute hidden"
                src={youtube}
                alt="Youtube"
              />
              <img
                className="h-6"
                src={youtube}
                alt="Youtube"
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
      </div >

      <div
        className="bg-gray-200 w-full mt-16"
        style={{ height: "1px" }}
      ></div>

      <div className="customContainer py-5">
        <div className="flex">
          <div className="text-sm">
            Copyright &copy; {new Date().getFullYear()}, Carets
            Corporation, All Rights Reserved.
          </div>
          <div className="ml-auto">
            <Link to="/">
              <img
                src={footer_logo}
                alt="Carets Logo"
                className="h-10"
              />
            </Link>
          </div>
        </div>
      </div>
      */}
    </footer >
  );
};

export default Footer;
