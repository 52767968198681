import React, { Component, Fragment } from "react";
import { Grid, Row, Col, Button } from "react-bootstrap";

import NewCampaigns from "../../../assets/img/NewCampaigns.svg";
import CreateAd from "../../../assets/img/CreateAd.svg";
import PaymentAd from "../../../assets/img/PaymentAd.svg";
import ActiveAd from "../../../assets/img/ActiveAd.svg";
import Audio from "../../../assets/img/audioFlow.svg";
import Splash from "../../../assets/img/splashFlow.svg";
import { adService } from "../../../services/ad";
import { splashService } from "../../../services/splash";
import { Link } from "react-router-dom";
import lib from "react-ckeditor-component";
import { licenseService } from "services/license";
import { musicService } from "services/music";

class DefaultIntro extends Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem("user"));
    const { id } = this.props.match.params;
    this.state = {
      default_ad: [],
      default_intro: [],
      default_finish: [],
      default_sound: [],

      xid: 0,
      adList: [],
      splashList: [],
      audioList: [],
      id: id,
      user_id: user.data ? user.data.id : null,
      searchSplash: '',
    };
  }

  componentDidMount() {
    this.getSplashCall(this.state.user_id, this.state.searchSplash);
    this.getdefaultCall();
  }

  getdefaultCall = () => {
       const data = {
         id:this.state.id
       }
    licenseService.CaretDefaultSettings(data).then((res) => {
      let index=0;
      let recState = { ...this.state.default_intro };
      recState[index] = res.data.default_intro
      this.setState({
        default_intro :recState
      })

    });
  }

  handleSearchSplashChange = (e) => {
    const searchSplash = e.target.value;
    this.setState({ searchSplash });
    this.getSplashCall(this.state.user_id, searchSplash);
  };

  getSplashCall = (val, searchSplash) => {
    splashService.getSplashes(val, searchSplash).then((res) => {
      // console.log("splashList:", res.data);
      this.setState({ splashList: res.data });
    });
  };

  handleIntroSelect = (selectedOptionType) => {
    // console.log("selectedOptionType", selectedOptionType);

    const filtered = this.state.splashList.filter((obj) => {
      return obj.value === selectedOptionType["value"];
    });
    console.log(filtered);

    if (filtered) {
      let recState = { ...this.state.default_intro };
      recState[this.state.xid] = filtered[0].value;
      this.setState({ default_intro: recState });
    }
  };


 

  handleToSaveDefaultSelected = () => {
    const data = {
      default_intro: this.state.default_intro,
      id: this.state.id,
    };
    licenseService.CaretDefaultSettings(data).then((res) => {
      // console.log(res);
      window.location.replace("/CLM/License");
      // window.location.reload();
    });
  };



 

  render() {
    const { tabs, default_ad, default_intro, default_finish, default_sound } =
      this.state;

    return (
      <Fragment>
        <div className="innerCustomContainer">
          

          <div>
     
              <div>
                <div className="">
                  <div
                    className="flexElem gap-2 items-center"
                    style={{ marginBottom: "20px", marginTop: "20px" }}
                  >
                    <h3 className="text-left font-bold m-0">
                      Select Default Intro
                    </h3>
                    <div className="flexElem ml-auto gap-2" style={{ gap: 10 }}>
                      <Button
                        type="submit"
                        className="btnDark px-5 font-boldF text-16"
                        disabled={!this.state.default_intro[this.state.xid]}
                        onClick={() => this.handleToSaveDefaultSelected()}
                      >
                        Proceed to Save
                      </Button>
                    </div>
                  </div>

                  <div className="gridSplash">
                    {this.state.splashList.map((splash, index) => (
                      <div>
                        <div className="splashWrap">
                          {/* {(index % 8 === 0)} */}
                          <video src={splash.video_url} className="adImage" />
                          <div
                            className="adContent flexElem flexColumn mt-auto gap10p"
                            style={{ wordBreak: "break-word" }}
                          >
                            <div style={{ marginTop: "au to" }}>
                              <h6
                                className="text-primary m-0"
                                style={{
                                  textTransform: "capitalize",
                                  fontSize: 11,
                                }}
                              >
                                Splash Title:
                              </h6>
                              <h5 className="m-0">{splash.label}</h5>
                            </div>
                            <div></div>
                          </div>
                        </div>
                        <button
                          className={`mt-1 selectBtn ${
                            this.state.default_intro[this.state.xid] ===
                            splash.value
                              ? "bcCelectBtn"
                              : "selectBtn"
                          }`}
                          onClick={() => this.handleIntroSelect(splash)}
                        >
                          Select
                        </button>
                        {/* {(index % 3 === 2 || index === this.state.splashList.length - 1)} */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
        

          </div>
        </div>
      </Fragment>
    );
  }
}

export default DefaultIntro;
