import Dashboard from "components/Admin/Dashboard";
import AdminUsers from "components/Admin/Users/AdminUsers";
import AppUsers from "components/Admin/Users/AppUsers";
import Roles from "components/Admin/Roles/RolesList";
import Permissions from "components/Admin/Permissions/PermissionsList";
import dashboardIcon from "assets/img/dashboard.svg";
import userlIcon from "assets/img/user.svg";
import ModulePermissions from "../components/Admin/Module/ModulePermissions";
import MusicCategories from "components/Admin/Music/MusicCategories";
import MusicListing from "components/Admin/Music/MusicListing";
import VideoListing from "components/Admin/Videos/VideoListing";
import SettingsListing from "components/Admin/Settings/SettingsListing";
import PagesListing from "components/Admin/CMS/PagesListing";
/* ################## */
import AdUsers from "components/Admin/Advertisers/AdUsers";
import AdListing from "components/Admin/Ads/AdListing";
import PlanListing from "components/Admin/Ads/PlanListing";
import CampaignListing from "components/Admin/Ads/CampaignListing";
import CardListing from "components/Admin/Ads/CardListing";
import PaymentListing from "components/Admin/Ads/PaymentListing";
import CampaignAdsListing from "components/Admin/Ads/CampaignAdsListing";
import Graph1 from "components/Admin/Ads/Graph1";
import Graph2 from "components/Admin/Ads/Graph2";
import Graph3 from "components/Admin/Ads/Graph3";
/* ################## */
import CLMUsers from "components/Admin/CLM/CLMUsers";
import CLMPlanListing from "components/Admin/CLM/CLMPlanListing";
import HashListing from "components/Admin/Hashes/HashListing";
import HashCategoryListing from "components/Admin/Hashes/HashCategoryListing";
import LicenseListing from "components/Admin/CLM/LicenseListing";
import CLMPaymentListing from "components/Admin/CLM/CLMPaymentListing";
import SplashListing from "components/Admin/CLM/SplashListing";

import KeywordListing from "components/Admin/CLM/Keyword/KeywordListing";
import CaretPricingListing from "components/Admin/CLM/CaretPricing/CaretPricingListing";

import CaretsVideosListing from "components/Admin/CLM/caretVideos/CaretsVideosListing";
import MyCaretsForm1 from "components/Admin/CLM/caretVideos/MyCaretsForm1";



import CorporateListing from "components/Admin/CLM/CorporateListing";
import Certificate from "components/Admin/pdf/Email";


//import VideoUploader from "components/Admin/Test/VideoUploader";

var dashboardRoutes = [
    {
        path: "/Admin/dashboard",
        name: "Dashboard",
        icon: "pe-7s-graph",
        component: Dashboard,
        iconImagePath: dashboardIcon,
        permission: "admin-dashboard"
    },
    {
        path: "/Admin/settings",
        name: "Settings",
        icon: "pe-7s-graph",
        component: SettingsListing,
        iconImagePath: dashboardIcon,
        permission: "admin-settings"
    },
    {
        path: "/Admin/cms/pages",
        name: "Pages",
        icon: "pe-7s-graph",
        component: PagesListing,
        iconImagePath: dashboardIcon,
        permission: "cms-pages-listing"
    },
    {
        path: "/Admin/users",
        name: "Users",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: AdminUsers,
        permission: "user-list"
    },
    // {
    //   path: "/Admin/:id/agency-users",
    //   name: "Agency Users",
    //   icon: "pe-7s-users",
    //   iconImagePath: userlIcon,
    //   component: AgencyUsers,
    //   permission: "agency-user-list"
    // },
    {
        path: "/Admin/roles",
        name: "Roles",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: Roles,
        permission: "role-list"
    },
    {
        path: "/Admin/modules/:id?",
        name: "Modules",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: ModulePermissions,
        permission: "role-list"
    },
    {
        path: "/Admin/permissions",
        name: "Permissions",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: Permissions,
        permission: "permission-list"
    },
    {
        path: "/Admin/appusers",
        name: "App Users",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: AppUsers,
        permission: "app-user-list"
    },
    {
        path: "/Admin/audio-cats",
        name: "Audio Categories",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: MusicCategories,
        permission: "audio-categories-list"
    },
    {
        path: "/Admin/audios/:id?",
        name: "Audios",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: MusicListing,
        permission: "audios-list"
    },
    {
        path: "/Admin/videos",
        name: "Videos",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: VideoListing,
        permission: "videos-list"
    },

    /*########################*/

    {
        path: "/Admin/adusers",
        name: "Advertisers",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: AdUsers,
        permission: "ad-user-list"
    },

    {
        path: "/Admin/ads/:id?",
        name: "Ads",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: AdListing,
        permission: "ads-list"
    },

    {
        path: "/Admin/plans",
        name: "Ads",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: PlanListing,
        permission: "plans-list"
    },

    {
        path: "/Admin/campaigns/:id?",
        name: "Ads",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: CampaignListing,
        permission: "campaigns-list"
    },

    {
        path: "/Admin/campaign-details/:id?",
        name: "Ads",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: CampaignAdsListing,
        permission: "campaigns-ads-list"
    },

    {
        path: "/Admin/cards/:id?",
        name: "Ads",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: CardListing,
        permission: "cards-list"
    },

    {
        path: "/Admin/payments/:id?",
        name: "Ads",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: PaymentListing,
        permission: "payments-list"
    },
    {
        path: "/Admin/Graph1",
        name: "Graph1",
        icon: "pe-7s-users",
        iconImagePath: dashboardIcon,
        component: Graph1
        //permission: "payments-list"
    },
    {
        path: "/Admin/Graph2",
        name: "Graph2",
        icon: "pe-7s-users",
        iconImagePath: dashboardIcon,
        component: Graph2
        //permission: "payments-list"
    },
    {
        path: "/Admin/Graph3",
        name: "Graph3",
        icon: "pe-7s-users",
        iconImagePath: dashboardIcon,
        component: Graph3
        //permission: "payments-list"
    },
    // ###################

    {
        path: "/Admin/clmUsers",
        name: "CLM",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: CLMUsers,
        permission: "clm-user-list"
    },
    {
        path: "/Admin/clmPlans",
        name: "CLM",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: CLMPlanListing,
        permission: "plans-list"
    },
    {
        path: "/Admin/keyword",
        name: "CLM",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: KeywordListing,
        permission: "keyword-list"
    },
    {
        path: "/Admin/caretPricing",
        name: "CLM",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: CaretPricingListing,
        permission: "caret-pricing-list"
    },
    {
        path: "/Admin/caret-videos",
        name: "CLM",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: CaretsVideosListing,
        permission: "caret-video-list"
    },
    {
        path: "/Admin/manual-caret",
        name: "CLM",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: MyCaretsForm1,
        permission: "manual-caret-video"
    },
    {
        path: "/Admin/clmLicense/:id?",
        name: "CLM",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: LicenseListing,
        permission: "license-list"
    },
    {
        path: "/Admin/corporateRequest/:id?",
        name: "Corporate",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: CorporateListing,
        permission: "request-list"
    },
    {
        path: "/Admin/CLMPayments/:id?",
        name: "CLM",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: CLMPaymentListing,
        permission: "clm-payments-list"
    },
    {
        path: "/Admin/splashListing/:id?",
        name: "Audios",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: SplashListing,
        permission: "clm-splash-list"
    },
    {
        path: "/Admin/hash",
        name: "Hash",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: HashListing,
        permission: "hash_list"
    },
    {
        path: "/Admin/hash-category",
        name: "Hash",
        icon: "pe-7s-users",
        iconImagePath: userlIcon,
        component: HashCategoryListing,
        permission: "hash-category-list"
    },
    {
        path: "/Admin/clm-certificate/:id?",
        name: "certificate",
        icon: "pe-7s-users",
        iconImagePath: dashboardIcon,
        component: Certificate,
        permission: "certificate"
    },
    {
        redirect: true,
        path: "/Admin",
        to: "/Admin/dashboard",
        name: "Dashboard"
    }
];

export default dashboardRoutes;
