import React, { Component, Fragment } from 'react';
import { Link } from "react-router-dom";
import { Grid, Row, Col } from "react-bootstrap";
import { Button, Badge } from "reactstrap";
import Footer from "../FrontEnd/Footer/Footer"
import Header from "../FrontEnd/Header/Header"
import Heart from "../../assets/img/heart.svg"
import Cart from "../../assets/img/cart.svg"
import SearchIcon from "../../assets/img/search.svg"
import { licenseService } from "../../services/license";
import { toast } from 'react-toastify';

// import { corporateService } from "../services/corporate";
import axios from "axios";

class Search extends Component {
  s
  constructor(props) {
    super(props);

    var checkLogin = localStorage.getItem('user');
    var caret_title = localStorage.getItem('caret_title');
    if (checkLogin) {
      localStorage.removeItem('user');
    }
    this.state = {
      value: caret_title ? caret_title : "",
      results: [],
      suggestions: [],
      price: [],
      topSugPrice: [],
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheckKeyword = () => {
    const { value } = this.state;

    licenseService.keywordCheckCaret(value).then((res) => {
      if (res.status === 403) {
        toast.error(res.message);
        setTimeout(() => {
          window.location.reload();
        }, 700);
        return;
      } else {
        if (res.status === 200) {
          this.handleSearchClisification(value);
        }
      }
    })

  }


  handleSubmit(keyword, classification, networth, google_search_volume, instagram, twitter) {
    licenseService.checkCaretAvailability(keyword, classification, networth, google_search_volume, instagram, twitter).then((res) => {
      console.log("######### PP=> ", res);

      this.setState({
        results: res,
        price: Array.isArray(res.price) && res.price.length > 0 ? res.price[0] : [],
        topSugPrice: Array.isArray(res.topSuggestion.price) && res.topSuggestion.price.length > 0 ? res.topSuggestion.price[0] : [],
        suggestions: res.suggestions,

      })
    })
  }



  mapDataWithPatterns = (data) => {
    const mappedData = {};

    const patterns = {
      keyword: /keyword/i,
      classification: /classification|class/i,
      net_worth: /net_worth|networth|net worth|worth/i,
      google_search_volume: /google_search_volume|google search volume|search volume/i,
      instagram: /instagram|instagram followers|insta/i,
      twitter: /twitter|twitter followers|twitter/i
    };

    Object.keys(patterns).forEach((standardKey) => {
      // Find the first matching key using the pattern
      for (let key in data) {
        if (patterns[standardKey].test(key)) {
          mappedData[standardKey] = data[key];
          break; // Stop after the first match
        }
      }
    });

    return mappedData;
  };

  componentDidMount() {
    this.handleCheckKeyword();
  }

  handleSearchClisification = async () => {
    const { value } = this.state;
    localStorage.removeItem('caret_title');
    try {
      const response = await axios.get(`https://search.carets.tv/api/generate-text?keyword=${value}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors'

        }
      );
      console.log('Response &&&&&&&&&& :', response);
      // this.handleSubmit(response.keyword, response.classification);

      const mappedData = this.mapDataWithPatterns(response);

      console.log('Mapped Data:', mappedData);
      this.handleSubmit(mappedData.keyword, mappedData.classification, mappedData.net_worth, mappedData.google_search_volume, mappedData.instagram, mappedData.twitter);

      // Pass the mapped data to store function
      this.handleStoreSearchData(mappedData);

      // const { keyword, classification, net_worth, google_search_volume, instagram, twitter } = response; 
      // const data = { keyword, classification, net_worth, google_search_volume, instagram, twitter };
      // console.log('Data:', data);
      // this.handleStoreSearchData(data);
      // this.setState({ searchResult: response.data });
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  // handleStoreSearchData = (value) => {
  //   console.log("VALUE => ",value);
  //   corporateService.storeCaretSearchData(value).then((res) => {
  //     console.log("######### res => ",res);
  //   })
  // }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleCheckKeyword();
    }
  }



  render() {
    const { results, suggestions, price, topSugPrice } = this.state;
    // console.log('aaaaaaaa ', results, suggestions)
    return (
      <Fragment>
        <main className='content pl-2-5F pt-2'>
          <div className='clm-search'>
            <Button style={{ backgroundColor: "transparent", border: "none" }}><img src={SearchIcon} alt='SearchIcon' width={24} /></Button>
            <input type='text'
              placeholder='Reserve your ^Caret Today!'
              name="value"
              className='form-control'
              value={this.state.value}
              onChange={this.handleChange}
              onKeyDown={this.handleKeyDown}
            />
            <Button className="searchBtn" disabled={this.state.value === ''} onClick={this.handleCheckKeyword}>Search ^Carets</Button>
          </div>
          <div className='container'>
            {results && (results.status === "available" || results.status === "unavailable") && (
              <Row>
                <Col xs={12} md={6}>
                  <div className='mainResult'>
                    <div className='py-50 px-2 searchBadge'>
                      Exact Match
                    </div>
                    {/* <h4>{this.state.value}</h4> */}
                    <h4 className='mt-20'>{results.message}</h4>
                    <h4 className='mt-0'>{results.plan_type}</h4>
                    {price.length === 0 ? (
                      <div></div>
                    ) : (
                      <h2 className='price mb-2'>$ {price.one_year_license}</h2>

                    )
                    }
                    {/* <h2 className='price mb-2'>{results.price}</h2> */}

                    {results.plan_type &&

                      <Link to={`/CLM-login?keyword=${this.state.value}`}>
                        <Button color='primary' className='mt-2 px-4 btn btnDark text-white' disabled={results.status === "unavailable"}>
                          Make it yours
                        </Button>
                      </Link>
                    }

                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className='mainResult'>
                    <div className='py-50 px-2 searchBadge'>
                      Exact Match Alternate
                    </div>
                    <h4 className='mt-20'>{results.topSuggestion.keyword}</h4>
                    <h4 className='mt-0'>{results.topSuggestion.plan_type}</h4>
                    <h2 className='price mb-2'>${topSugPrice.one_year_license}</h2>

                    {results.topSuggestion.plan_type &&
                      <Link to={`/CLM-login?keyword=${this.state.value}`}>

                        <Button color='primary' className='mt-2 px-4 btn btnDark text-white'>
                          Make it yours
                        </Button>
                      </Link>
                    }
                  </div>
                </Col>
              </Row>
            )}
            {/* ########## SUGGESTIONS ########## */}
            {results && (results.status === "available" || results.status === "unavailable") && (
              <h4>Tailored Other Suggestions</h4>

            )}
            {suggestions && suggestions.length > 0 && (
              suggestions.map((suggestion, index) => (
                <div className='suggestions' key={index}>
                  <div className='suggestionsList flexElem alignCenter gap25'>
                    {/* <Button size="sm">
                  <img src={Heart} alt='Heart' width={14} />
                </Button> */}
                    <h4 className='m-0'>{suggestion.keyword}</h4>
                    <div className='ms-auto flexElem alignCenter gap25'>
                      <h4 className='text-primary m-0'>${suggestion.price.one_year_license}</h4>
                      {suggestion.plan_type &&
                        <Link to={`/CLM-login?keyword=${this.state.value}`}
                          className="cartFilter">
                          <img src={Cart} alt='Cart' width={24} />
                        </Link>
                      }
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </main>
      </Fragment >
    );
  }
}

export default Search;
